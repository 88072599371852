import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useEffect, useState } from "react";
import { BtnLoader } from "src/commonLoader/index";
import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import EditIcon from "../../assests/images/EmployeeOrder/Vector (1).png";
import { GET_EXISTING_CUSTOMER } from "src/constants/queries";
import AddNewCustomerModal from "../AddNewCustomerModal/AddNewCustomerModal";
import { useAuth } from "src/contexts/auth-provider";
import { useNavigate } from "react-router-dom";

const CheckExistingCustomerModal = ({ onHide, onCustomerSelect }: any) => {
  const { axBe } = useAxiosInterceptor();
  const [loading, setLoading] = useState(false);
  const [existingCustomer, setExistingCustomer] = useState<any>([]);
  const [number, setNumber] = useState("");
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [editCustomer, seteditCustomer] = useState();
  const { hasRestaurant } = useAuth();
  const navigate = useNavigate();

  const handleButtonClick = (value: any) => {
    if (value === "⌫") {
      setNumber((prevNumber) => prevNumber.slice(0, -1));
    } else {
      setNumber((prevNumber) => prevNumber + value);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9\(\)\-\.\ ]/g, "");
    setNumber(formattedValue);
  };

  useEffect(() => {
    if (number) {
      const getExistingCustomer = async () => {
        setLoading(true);
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_EXISTING_CUSTOMER,
            variables: {
              customersByPhoneNumberOrEmailInput :{
                searchKeyword: number,
                storeCorrelationId : hasRestaurant?.correlationId
              }
            },
          });
          setExistingCustomer(data?.data?.customersByPhoneNumber);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
      getExistingCustomer();
    }
  }, [number, axBe]);

  const handleEditClick = (customer: any, index: number) => {
    onCustomerSelect(customer);
    onHide();
  };

  const handleAddCustomer = () => {
    setShowAddCustomerModal(true);
  };

  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div
                className={`bg-white px-8 py-6 rounded-lg w-[820px] max-w-[820px] min-w-10 flex flex-col`}
              >
                <div className="top flex justify-between items-center">
                  <div>
                    <h1 className="text-lg font-semibold">Customer</h1>
                  </div>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt=""
                    onClick={() => navigate("/active-order")}
                  />
                </div>
                <div className="flex gap-x-6">
                  <div className="w-[50%] mt-8 border border-[#F1F1F2] p-2 rounded-md h-[500px] overflow-y-scroll">
                    {loading ? (
                      <div className="flex justify-center items-center h-full">
                        <BtnLoader />
                      </div>
                    ) : existingCustomer?.length > 0 ? (
                      existingCustomer?.map((customers: any, index: number) => {
                        return (
                          <div
                            onClick={() => handleEditClick(customers, index)}
                            className="cursor-pointer border relative border-[#F1F1F2] p-2 rounded-md flex justify-between items-center my-2"
                            key={customers?.phoneNumber}
                          >
                            <div>
                              <h1 className="text-[#666666]">
                                {customers?.phoneNumber}
                              </h1>
                              <h1 className="text-[#666666] text-sm">
                                {customers?.firstName}, {customers?.lastName},{" "}
                                {customers?.email}
                              </h1>
                            </div>
                            <div>
                              <img
                                className="absolute right-[8px] top-[20px] cursor-pointer"
                                src={EditIcon}
                                alt="Edit Icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  seteditCustomer(customers);
                                  setShowAddCustomerModal(true);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="mt-2 flex items-center justify-center flex-col h-full">
                        <h1>NO RESULT FOUND.</h1>
                        {/* <button
                          className="bg-[#FD7657] text-white py-2 rounded-lg shadow-lg w-[100px] text-sm mt-2"
                          onClick={onHide}
                        >
                          Skip
                        </button> */}
                      </div>
                    )}
                  </div>

                  <div className="w-[50%] mt-8">
                    <h1>
                      Phone <span className="text-[red]">*</span>
                    </h1>
                    <div className="flex flex-col mt-2">
                      <div className="w-full text-center mb-4">
                        <input
                          type="text"
                          placeholder="(XXX) XXX-XXXX"
                          value={number}
                          onChange={handleInputChange}
                          className="w-full border border-[#F1F1F2] rounded-lg text-3xl font-semibold text-center py-2"
                        />
                      </div>
                      <div className="grid grid-cols-3 gap-4 text-center text-2xl">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0].map((value) => (
                          <button
                            key={value}
                            className="py-4"
                            onClick={() => {
                              handleButtonClick(value);
                            }}
                          >
                            {value}
                          </button>
                        ))}
                        <button
                          className="py-4"
                          onClick={() => handleButtonClick("⌫")}
                        >
                          ⌫
                        </button>
                      </div>
                      {existingCustomer?.length === 0 && (
                        <div className="mt-8">
                          <button
                            className={`w-full bg-[#FD7657] text-white py-3 rounded-lg shadow-lg`}
                            onClick={handleAddCustomer}
                          >
                            Add new customer
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddCustomerModal && (
        <AddNewCustomerModal
        customerData={editCustomer}
          onClose={() => setShowAddCustomerModal(false)}
        />
      )}
    </div>
  );
};

export default CheckExistingCustomerModal;
