import { BtnLoader } from "src/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "src/contexts/auth-provider";
import OrderCard from "src/components/OrderCard/OrderCard";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { UPDATE_CUSTOMER_ORDER } from "src/constants/mutations";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { PayBillModal } from "src/components/PayBillModal/PayBillModal";
import { GET_CUSTOMER_ORDER_FOR_EMPLOYEE } from "src/constants/queries";
import { OrdersDetailsEmployee } from "src/components/OrdersDetails/OrdersDetailsEmployee";
import DropIcon from "../../assests/images/landing-page/Vector (1).png";

const orderTypeMapping: any = {
  DINE_IN: "Dine In",
  PICK_UP: "Pick Up",
  DELIVERY: "Delivery",
};

export const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const { axBe } = useAxiosInterceptor();
  const topRef = useRef<any>(null);
  const { hasRestaurant, user, userRole } = useAuth();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showOrderTab, setShowOrderTab] = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [orders, setOrders] = useState<any>([]);
  const [filterOrder, setFilterOrder] = useState<any>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [orderType, setOrderType] = useState<string | null>(null);

  const moveToOrder = (orderTypeCheck: any) => {
    if (orderTypeCheck === "DINE_IN") {
      navigate(
        `/create-new-order${"?restaurantId="}${hasRestaurant?.correlationId}`,
        { state: { selectedOrderType: orderTypeCheck } }
      );
    }
    if (orderTypeCheck === "PICK_UP") {
      navigate(
        `/create-new-order${"?restaurantId="}${hasRestaurant?.correlationId}`,
        { state: { selectedOrderType: orderTypeCheck } }
      );
    }
    if (orderTypeCheck === "DELIVERY") {
      navigate(
        `/create-new-order${"?restaurantId="}${hasRestaurant?.correlationId}`,
        { state: { selectedOrderType: orderTypeCheck } }
      );
    }
  };

  const handleShowDetails = (order: any, type: any) => {
    if (type === "pay bills") {
      setShowCreateModal(true);
    } else {
      setShowOrderTab(true);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setSelectedOrder(order);
  };

  useEffect(() => {
    getOrders();
  }, [hasRestaurant]);

  const getOrders = async (isComingToUpdate: boolean = false) => {
    setLoading(true);
    const isEmployee = userRole.toLowerCase().includes("employee");
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMER_ORDER_FOR_EMPLOYEE,
        variables: {
          storeCorrelationId: isEmployee
            ? user?.storeUser?.stores?.[0]?.correlationId
            : hasRestaurant?.correlationId,
        },
      });
      setOrders((prev: any) => [
        ...prev,
        ...(data?.data?.activeCustomerOrdersByStoreCorrelationId || []),
      ]);

      if (isComingToUpdate) {
        setFilterOrder(
          data?.data?.activeCustomerOrdersByStoreCorrelationId || []
        );
      } else {
        setFilterOrder((prev: any) => [
          ...prev,
          ...(data?.data?.activeCustomerOrdersByStoreCorrelationId || []),
        ]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const searchOrders = (search: string) => {
    setFilterOrder(
      (orders || [])?.filter(
        (order: any) =>
          order?.customerFirstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          order?.customerEmail?.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const handleSelectChange = (event: any) => {
    const selectedStatus = event.target.value;
    setFilterOrder(
      (orders || [])?.filter((order: any) =>
        order?.orderType?.toLowerCase().includes(selectedStatus.toLowerCase())
      )
    );
  };

  const updateOrder = async (updatedOrder: any) => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_CUSTOMER_ORDER,
        variables: updatedOrder,
      });
      if (data?.data?.updateCustomerOrder) {
        setShowOrderTab(false);
        await getOrders(true);
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setShowOrderTab(false);
    }
  };

  return (
    <>
      <div
        ref={topRef}
        className={`${showOrderTab ? "flex justify-between gap-x-6" : ""}`}
      >
        <div>
          <div className="flex justify-between items-center flex-wrap">
            <div className="flex items-center gap-x-3">
              <form className="w-[280px]">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                    placeholder="Search Orders..."
                    onChange={(e) => searchOrders(e.target.value)}
                  />
                </div>
              </form>
              <select
                name="roles"
                className="text-[#] h-[46px] text-xs w-[120px] border rounded-lg border-[#F1F1F2] px-2 cursor-pointer"
                onChange={handleSelectChange}
              >
                <option className="text-[]" value="">
                  All
                </option>
                <option className="text-[]" value="DINE_IN">
                  Dine-in
                </option>
                <option className="text-[]" value="PICK_UP">
                  Pick-up
                </option>
                <option className="text-[]" value="DELIVERY">
                  Delivery
                </option>
              </select>
            </div>
            <div className="flex gap-x-2 justify-center my-4 ">
              <button
                type="button"
                onClick={toggleDropdown}
                className={`w-full bg-orange py-3 pl-4 pr-5 flex items-center justify-between text-xs text-white border rounded-xl ${
                  dropdownOpen
                    ? "border-[#f97658] shadow-md"
                    : "border-gray-300"
                } text-left focus:outline-none`}
              >
                {orderTypeMapping[orderType as any] || "New Order"}
                <div>
                  <img
                    className="h-[6px] w-[9px] ml-3 white-arrow"
                    src={DropIcon}
                    alt=""
                  />
                </div>
              </button>
              {dropdownOpen && (
                <div className="absolute z-10 mt-[42px] mr-[35px] w-[160px] bg-white border  rounded-lg border-[#F1F1F2] shadow-lg">
                  <ul className="py-1">
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F]  ${
                        orderType === "DINE_IN" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderType("DINE_IN");
                        moveToOrder("DINE_IN");
                        setDropdownOpen(false);
                      }}
                    >
                      New Order Dine In
                    </li>
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F] ${
                        orderType === "PICK_UP" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderType("PICK_UP");
                        moveToOrder("PICK_UP");
                        setDropdownOpen(false);
                      }}
                    >
                      New Order Pick Up
                    </li>
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F] ${
                        orderType === "DELIVERY" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderType("DELIVERY");
                        moveToOrder("DELIVERY");
                        setDropdownOpen(false);
                      }}
                    >
                      New Order Delivery
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex gap-3">
            <div className="w-full">
              {loading ? (
                <div className="absolute top-[50%] right-[37%]">
                  <BtnLoader />
                </div>
              ) : filterOrder && filterOrder?.length > 0 ? (
                // <div className="flex flex-wrap">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:gap-x-6 gap-x-2 gap-y-5">
                  {filterOrder?.map((order: any, index: any) => (
                    <OrderCard
                      key={index}
                      order={order}
                      handleShowDetails={handleShowDetails}
                    />
                  ))}
                </div>
              ) : (
                <div className="absolute top-[50%] right-[37%]">
                  <p>Click the button on the top to create new order!</p>
                </div>
              )}
            </div>
          </div>
          {showCreateModal ? (
            <PayBillModal
              setShowCreateModal={() => setShowCreateModal(false)}
              orderDetails={selectedOrder}
            />
          ) : (
            <></>
          )}
        </div>
        {showOrderTab && (
          <div className="mt-4">
            <OrdersDetailsEmployee
              updateOrder={updateOrder}
              setShowOrderDetail={() => setShowOrderTab(false)}
              orderDetails={selectedOrder}
            />
          </div>
        )}
      </div>
    </>
  );
};
