import { ReactNode } from "react";
import { DefaultLayout } from "../layout/DefaultLayout";
import { Dashboard } from "../pages/dashboard/dashboard";
import { UploadImageRestaurant } from "../pages/restaurant-page/create-restaurant/UploadImageRestaurant";
import CreateRestaurant from "../pages/restaurant-page/create-restaurant/CreateRestaurant";
import Plans from "../pages/restaurant-page/create-restaurant/Plans";
import Success from "../pages/restaurant-page/success/Success";
import Cancel from "../pages/restaurant-page/cancel/Cancel";
import Refresh from "src/pages/restaurant-page/refresh/Refresh";
import Return from "src/pages/restaurant-page/return/Return";
import QrCodeModal from "src/components/QrCodeModal/QrCodeModal";
import DeleteRestaurant from "src/components/DeleteRestaurant/DeleteRestaurant";
import ShowTokenRefreshModal from "src/components/ShowTokenRefreshModal/ShowTokenRefreshModal";
import { RestaurantPage } from "../pages/restaurant-page/RestaurantPage";
import RestaurantSetting from "../pages/Restaurant-setting/RestaurantSetting";
import Employee from "src/pages/Employee/Employee";
import Promotions from "src/pages/PromotionManagement/Promotions";
import Order from "src/pages/OrderManagement/Order";
import InviteEmployee from "../components/InviteEmployeeModal/InviteEmployeeModal";
import Menu from "src/pages/Menu/Menu";
import RecentOrders from "src/pages/RecentOrders/RecentOrders";
import UserProfileAccount from "src/pages/UserProfileAccount/UserProfileAccount";
import SubcriptionSetting from "src/components/SubcriptionSetting/SubcriptionSetting";
import Combo from "src/pages/Combo/Combo";
import MenuItem from "src/components/MenuCard/MenuSubItems/MenuItem";
import RestaurantTable from "src/pages/RestaurantTable/RestaurantTable";
import UpdateCombo from "src/pages/Combo/UpdateCombo";
import { EmployeeDashboard } from "src/pages/EmployeeDashboard/EmployeeDashboard";
import { EmployeeCart } from "src/pages/EmployeeCart/EmployeeCart";
import { DefaultEmployeeLayout } from "src/layout/DefaultEmployeeLayout";
import { EmployeeProfile } from "src/pages/EmployeeProfile/EmployeeProfile";
import { CreateNewOrder } from "src/pages/CreateNewOrder/CreateNewOrder";
import { AvailbleFeatures } from "./isAllowedFeature";

interface Children {
  path: string;
  element?: ReactNode;
  isRoleBase?: boolean;
  children?: RouteInterface[];
  roleName?: AvailbleFeatures;
}
export interface RouteInterface {
  path: string;
  element: ReactNode; // ReactNode can be used for JSX elements
  children?: Children[]; // Nested routes
}

const routesForAuthenticatedUser: RouteInterface[] = [
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        isRoleBase: true,
        roleName: AvailbleFeatures.ReportingAndAnalytics,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/combo/:id",
        element: <UpdateCombo />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/dashboard",
        element: <Dashboard />,
        isRoleBase: false,
      },
      {
        path: "my-employee/:employeeCorrelationId/orders",
        element: <EmployeeDashboard />,
        isRoleBase: false,
      },
      {
        path: "my-employee/:employeeCorrelationId/combo",
        element: <Combo />,
        isRoleBase: true,
        roleName: AvailbleFeatures.ComboCreationAndManagement,
      },
      {
        path: "my-employee/:employeeCorrelationId/cart",
        element: <EmployeeCart />,
        isRoleBase: false,
      },
      {
        path: "my-employee/:restaurantCorrelationId/combo/:id",
        element: <UpdateCombo />,
        isRoleBase: true,
        roleName: AvailbleFeatures.ComboCreationAndManagement,
      },
      {
        path: "my-restaurants",
        element: <RestaurantPage />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/restaurant-setting",
        element: <RestaurantSetting />,
        isRoleBase: false,
      },
      {
        path: "create-restaurant",
        element: <CreateRestaurant />,
        isRoleBase: false,
      },
      {
        path: "upload-image",
        element: <UploadImageRestaurant />,
        isRoleBase: false,
      },
      {
        path: "plans",
        element: <Plans />,
        isRoleBase: false,
      },
      {
        path: "stripe-connection",
        element: <Plans />,
        isRoleBase: false,
      },
      {
        path: "cancel",
        element: <Cancel />,
        isRoleBase: false,
      },
      {
        path: "success",
        element: <Success />,
        isRoleBase: false,
      },
      {
        path: "connectedAccount/refresh/:connectedAccountId",
        isRoleBase: false,
        element: <Refresh />,
      },
      {
        path: "connectedAccount/return/:connectedAccountId",
        isRoleBase: false,
        element: <Return />,
      },
      {
        path: "qr-code",
        element: <QrCodeModal />,
        isRoleBase: false,
      },
      {
        path: "delete-restaurant",
        element: <DeleteRestaurant />,
        isRoleBase: false,
      },
      {
        path: "show-token-refresh-modal",
        element: <ShowTokenRefreshModal />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/employee",
        element: <Employee />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/promotions",
        element: <Promotions />,
        isRoleBase: true,
        roleName: AvailbleFeatures.PromotionManagement,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/ordermanagement",
        element: <Order />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/active-orders",
        element: <EmployeeDashboard />,
        isRoleBase: false,
      },
      {
        path: "active-order/cart",
        element: <EmployeeCart />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/restaurant-table",
        element: <RestaurantTable />,
        isRoleBase: true,
        roleName: AvailbleFeatures.TableManagement,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/menu",
        element: <Menu />,
        isRoleBase: true,
        roleName: AvailbleFeatures.MenuCreationAndManagement,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/menu/:id",
        element: <MenuItem />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/combo/:id",
        element: <UpdateCombo />,
        isRoleBase: false,
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/combo",
        element: <Combo />,
        isRoleBase: true,
        roleName: AvailbleFeatures.ComboCreationAndManagement,
      },
      {
        path: "invite-employee",
        element: <InviteEmployee />,
        isRoleBase: false,
      },
      {
        path: "recent-oreders",
        element: <RecentOrders />,
        isRoleBase: false,
      },
      {
        path: "user-profile-account",
        element: <UserProfileAccount />,
        isRoleBase: false,
      },
      {
        path: "subcription-setting",
        isRoleBase: false,
        element: <SubcriptionSetting />,
      },
      {
        path: "create-new-order",
        element: <CreateNewOrder />,
        isRoleBase: false,
      },
    ],
  },
];

export const routesForAuthenticatedEmployee: RouteInterface[] = [
  {
    path: "/",
    element: <DefaultEmployeeLayout />,
    children: [
      {
        path: "active-order",
        element: <EmployeeDashboard />,
      },
      {
        path: "employee-table",
        element: <RestaurantTable />,
      },
      // {
      //   path: "combo",
      //   element: <Combo/>
      // },
      // {
      //   path: "combo/:id",
      //   element: <UpdateCombo />,
      // },
      {
        path: "active-order/cart",
        element: <EmployeeCart />,
      },
      {
        path: "employee-profile",
        element: <EmployeeProfile />,
      },
      {
        path: "create-new-order",
        element: <CreateNewOrder />,
      },
    ],
  },
];

export default routesForAuthenticatedUser;
