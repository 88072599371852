import RightHeaderSection from "./RightHeaderSection";

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  user: any;
  pageTitle: any;
  notification?: any;
  isIncoming? : any;
  setIsincoming? : any;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  const pathName = window.location.pathname;

  return (
    <header className="sticky z-[1] top-0 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex h-[81px] flex-grow border-b-[#F1F1F2] border-b items-center justify-between px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="hidden sm:block">
          <div className="relative">
            {pathName === "/employee-profile" ? (
              <h1 className="font-medium text-2xl mt-3">User Profile</h1>
            ) : (
              <h1 className="font-medium text-2xl mt-3">{props?.pageTitle}</h1>
            )}
          </div>
        </div>
        <div className="flex items-center gap-3 2xsm:gap-7">
          <RightHeaderSection
            user={props?.user}
            notification={props?.notification}
            isIncoming={props?.isIncoming}
            setIsincoming={props?.setIsincoming}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
