import { useEffect, useRef, useState } from "react";
import MassegeIcon from "../../assests/images/dashboard/Container 2.png";
import MassegeIcon2 from "../../assests/images/dashboard/Vertical container 2.png";
import BellIconWithOutDot from "../../assests/svg/bell-icon.svg";
import {
  formatDateTime,
  formatTimeAgo,
} from "src/utils/date";
import { useDispatch } from "react-redux";
import { clear_notification, remove_notification } from "src/store/features/notification-slice";
import { useAuth } from "src/contexts/auth-provider";

const convertToReadableFormat = (str: string): string => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const NotificationDropDown = ({
  notification = [],
  setIsincoming,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {hasRestaurant} = useAuth()
  const dispatch = useDispatch()

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsincoming(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  console.log(notification, 'NOTIFY')

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative z-[9999] text-left" ref={dropdownRef}>
      <div className="cursor-pointer">
        <div className="relative">
          <img
            aria-expanded={isOpen}
            aria-haspopup="true"
            onClick={toggleDropdown}
            src={BellIconWithOutDot}
            alt=""
          />
          {notification?.length > 0 && (
            <span className="absolute top-1 right-1 text-[9px] z-[999] flex justify-center items-center text-white h-4 w-4 rounded-full bg-[red]">
              {notification?.length}
            </span>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-[99999] mt-2 w-[424px] h-[400px] overflow-y-scroll origin-top-right rounded-xl bg-white ring-opacity-5 py-3 focus:outline-none"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="flex items-center justify-between px-4">
            <h1 className="text-lg font-semibold">Notification</h1>
            <div className="flex gap-3 items-center">
              {notification?.length > 0 && (
                <div
                  className="text-sm text-[gray] hover:text-[#333] cursor-pointer"
                  onClick={()=> dispatch(clear_notification(hasRestaurant?.correlationId))}
                >
                  Clear all
                </div>
              )}
            </div>
          </div>
          <div className="py-1" role="none">
            {notification?.length === 0 && (
              <div className="flex py-1 px-4 gap-x-4 h-[100px] justify-center items-center">
                <p className="text-sm text-[#ccc]">No Notification</p>
              </div>
            )}
            {notification
              ?.slice()
              .reverse()
              .map((item: any) => {
                return (
                  <div className="flex justify-between items-center w-full py-2 duration-200 hover:bg-[#FAFAFA]">
                    <div className="flex py-1 px-4 gap-x-4">
                      <div className="flex items-start">
                        <div className="flex items-center gap-x-2">
                          <img
                            className="w-[6px] h-[6px]"
                            src={MassegeIcon}
                            alt=""
                          />
                          <img
                            className="w-[32px] h-[32px]"
                            src={MassegeIcon2}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm mb-[2px] font-semibold">
                          {convertToReadableFormat(item?.eventType)}
                        </p>
                        <p className="text-xs mb-[2px] truncate">
                          Order Id: {item?.orderId}
                        </p>
                        <p className="text-xs mb-[2px]">
                          Total amount: ${+item?.totalAmount}
                        </p>
                        <p className="text-xs mb-[2px]">
                          Status: {convertToReadableFormat(item?.orderStatus)}
                        </p>
                        <p className="text-xs mb-[2px]">
                          Created at: {formatDateTime(item?.orderCreatedAt)}
                        </p>
                        <p className="text-xs text-[#9B9B9B]">
                          {item?.orderCreatedAt &&
                            formatTimeAgo(item.orderCreatedAt)}
                        </p>
                      </div>
                    </div>

                    <div
                      onClick={() => dispatch(remove_notification({correlationId: hasRestaurant?.correlationId, removeOrderId: item?.orderId}))}
                      className="text-xl text-[gray] hover:text-[#333] pr-5 cursor-pointer"
                    >
                      &times;
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDropDown;
