import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth-provider";
import SignOut from "../../assests/images/dashboard/logout.png";
import Dropdown from "../Dropdown/NotificationDropDown";

const RightHeaderSection = ({ user, role, notification = [],isIncoming, setIsincoming }: any) => {
  const navigate = useNavigate();
  const { signOut, userRole } = useAuth();
  const { hasRestaurant } = useAuth();

  const handleProfile = () => {
    if (userRole?.toLowerCase()?.includes("employee")) {
      navigate("/employee-profile");
    }
  };

  return (
    <div className="relative ">
      <div className="flex items-center gap-y-6 gap-x-4">
        <span className="hidden text-right lg:block"></span>
        <div>
          {hasRestaurant?.correlationId && (
            <Dropdown
              notification={notification}
              isIncoming={isIncoming}
              setIsincoming={setIsincoming}
            />
          )}
        </div>
        <span
          className="h-11 w-11 rounded-lg bg-[#FEA793] flex justify-center items-center cursor-pointer"
          onClick={handleProfile}
        >
          <h1 className="text-white text-sm">
            {user?.storeUser?.firstName.slice(0, 1)?.toUpperCase()}
            {user?.storeUser?.lastName.slice(0, 1)?.toUpperCase()}
          </h1>
        </span>
        <div className="">
          <h1>
            {user?.storeUser?.firstName} {user?.storeUser?.lastName}
          </h1>
          {userRole?.toLowerCase()?.includes("admin") ? (
            <p className="text-sm">
              {user?.storeUser?.stores?.length} Restaurants
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="">
          <img
            className="w-[22px] h-[22px] cursor-pointer"
            src={SignOut}
            alt=""
            onClick={() => {
              signOut();
              navigate("/");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RightHeaderSection;
