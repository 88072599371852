import {useEffect} from "react";
import SearchForm from "../../components/Searchform/SearchFrom";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {delete_restaurant_modal, set_restaurants,} from "src/store/features/restuarant-slice";
import RestaurantCard from "src/components/RestaurantCard/RestaurantCard";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { GET_ALL_RESTAURANTS } from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";

export const RestaurantPage = () => {
  const { axBe } = useAxiosInterceptor();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const { filteredRestaurants } = useSelector(
    (state: any) => state.restaurant
  );
  const dispatch = useDispatch();

  const handleRestaurantClick = (restaurantId: string) => {
    dispatch(delete_restaurant_modal(restaurantId));
  };

  

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axBe.post(GRAPHQL_ENDPOINT, {
          query: GET_ALL_RESTAURANTS,
        });
        const fetchedRestaurants = response.data?.data?.loggedInUserStores;
        if (Array.isArray(fetchedRestaurants)) {
          dispatch(set_restaurants({restaurants: fetchedRestaurants, setUser}));
        } else {
          console.error("Unexpected result structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchRestaurants()
  }, [axBe, dispatch, setUser])
  


  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <SearchForm placeholder="Search by name..." />
        </div>
        <div>
          <div className="flex justify-center my-4">
            <button
              className="hover:bg-white hover:text-orange duration-500 w-[200px] text-white h-[42px] rounded-xl bg-[#FD7657]"
              onClick={() => navigate("/create-restaurant")}
            >
              &#65291; Add Restaurant
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-6 gap-3">
        {!filteredRestaurants?.length ? (
          <div className="w-[100%] flex justify-center items-center mt-5 text-[#666666] text-sm">
            <span>No restuantant found</span>
          </div>
        ) : (
          filteredRestaurants.map(
              (restaurant: any) => (<RestaurantCard isDashboard={false} restaurant={restaurant} handleRestaurantClick={handleRestaurantClick} />)
          )
        )}
      </div>
    </>
  );
};