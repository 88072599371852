import CrossIcon from "../../assests/images/dashboard/Vector 5.png";
import ArrowUpIcon from "../../assests/images/dashboard/Container 3.png";
import ArrowDownIcon from "../../assests/images/dashboard/Rating container.png";
import DownloadIcon from "../../assests/images/dashboard/download.png";
import ShareIcon from "../../assests/images/dashboard/Vector 6.png";
import { formatDateTime } from "src/utils/date";
import { useAuth } from "src/contexts/auth-provider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { CAPTURE_TIP } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { BtnLoader } from "src/Loader";
import { itemTotalTaxCalc } from "src/utils/order-price-calculation";
import { GET_RECEIPT } from "src/constants/queries";

export const OrdersDetails = ({
  setShowCreateModal,
  orderDetails,
  manageOrderCardDetail,
  updateOrder,
}: any) => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [captureLoading, setCaptureLoading] = useState(false);

  const totalTax = itemTotalTaxCalc(orderDetails?.items)

  const formik = useFormik({
    initialValues: {
      tip: undefined,
    },
    validationSchema: Yup.object().shape({
      tip: Yup.string().required(""),
    }),
    onSubmit: () => {},
  });

  const handleSelectChange = (event?: any) => {
    const selectedStatus = event.target.value;
    updateUserOrder(selectedStatus);
  };

  const updateUserOrder = async (selectedStatus?: any) => {
    const variables = {
      updateCustomerOrderInput: {
        storeCorrelationId: hasRestaurant?.correlationId,
        customerOrderCorrelationId: orderDetails?.correlationId,
        orderStatus: selectedStatus
          ? selectedStatus
          : orderDetails?.orderStatus,
        tip: formik?.values?.tip ? String(formik?.values?.tip) : null,
      },
    };
    updateOrder(variables);
  };

  useEffect(() => {
    if (orderDetails?.tip) {
      formik.setFieldValue("tip", orderDetails?.tip);
    }
  }, []);

  const isDisabled = !formik.isValid || !formik.dirty;

  const capturePayment = async () => {
    try {
      setCaptureLoading(true);
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: CAPTURE_TIP,
        variables: {
          captureCustomerOrderTipInput: {
            storeCorrelationId: hasRestaurant?.correlationId,
            customerOrderCorrelationId: orderDetails?.correlationId,
          },
        },
      });
      if (data?.data?.captureCustomerOrderTip) {
        successToast("Order has been Captured successfully.");
        setCaptureLoading(false);
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setCaptureLoading(false);
    }
  };

  const downloadReceipt = async () => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_RECEIPT,
        variables: {
          getReceiptPreSignedUrlInput:{
            customerOrderCorrelationId: orderDetails?.correlationId,
            storeCorrelationId: hasRestaurant?.correlationId
          } 
        },
      });      
      if (data?.data?.receiptByCustomerOrderCorrelationIdForStores) {
        downloadPDF(data?.data?.receiptByCustomerOrderCorrelationIdForStores?.customerCopy);
        successToast("Order has been Downloaded successfully.");
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
  };

  const downloadPDF = (url: string) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${orderDetails?.correlationId}.pdf`;
        alink.click();
      });
    });
  };



  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 h-full justify-end p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform h-full w-[40%] overflow-y-auto bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="p-8">
              <div className="flex justify-between w-full">
                <div className="flex items-center">
                  <img
                    onClick={setShowCreateModal}
                    className="mr-6 cursor-pointer"
                    src={CrossIcon}
                    alt=""
                  />
                  <img
                    onClick={() => manageOrderCardDetail("increment")}
                    className=" cursor-pointer"
                    src={ArrowUpIcon}
                    alt=""
                  />
                  <img
                    onClick={() => manageOrderCardDetail("decrement")}
                    className=" cursor-pointer"
                    src={ArrowDownIcon}
                    alt=""
                  />
                </div>
                <div className="flex items-center gap-x-5">
                  <img
                    className="w-[16px] h-[16px] cursor-pointer"
                    src={DownloadIcon}
                    alt=""
                    onClick={downloadReceipt}
                  />
                  <img className=" cursor-pointer" src={ShareIcon} alt="" />
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div>
                  <h1 className="text-xl font-semibold">
                    {orderDetails?.correlationId || "-"}
                  </h1>
                </div>
                <div>
                  <span
                    className={`${
                      orderDetails?.orderStatus?.toLowerCase() === "voided" &&
                      "bg-[#F144451A] text-[#F14445]"
                    } ${
                      orderDetails?.orderStatus?.toLowerCase() ===
                        "completed" && "bg-[#3ACC481A] text-[#3ACC48]"
                    } ${
                      orderDetails?.orderStatus?.toLowerCase() === "pending" &&
                      "bg-[#3A51CC1A] text-[#3A51CC]"
                    } rounded-[8px] py-2 px-4 text-xs`}
                  >
                    {orderDetails?.orderStatus.charAt(0).toUpperCase() +
                      orderDetails?.orderStatus.slice(1).toLowerCase() || "-"}
                  </span>
                </div>
              </div>
              <h1 className="text-sm text-[#666666]">
                {formatDateTime(orderDetails?.createdAt) || "-"}
              </h1>
              <h1 className="text-base text-[#1A1A1A] font-bold mt-6">
                Order Details
              </h1>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Order Type</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.orderType
                    ?.replace(/_/g, " ")
                    .toLowerCase()
                    .replace(/^./, (char: any) => char.toUpperCase()) || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Table Number
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.tableNumber || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Total Amount
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  $ {(Number(orderDetails?.totalPrice) + totalTax)?.toFixed(2)}
                </h1>
              </div>

              <div className="flex mt-3 items-center gap-x-4">
                <h1 className="text-[#666666] text-sm w-[180px]">Tip:</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  <input
                    className="text-[#1A1A1A] text-xs w-[100px] font-medium border border-[#bebdbd86] rounded-md py-2 px-2"
                    id="tip"
                    name="tip"
                    value={formik?.values?.tip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="nnumber"
                    placeholder="Enter Tip"
                  />
                </h1>
              {
                orderDetails?.tip ? <></> :
              <div className="flex items-center gap-x-2">
                <div className="flex items-center">
                  <button
                    onClick={handleSelectChange}
                    disabled={isDisabled}
                    className={`${
                      isDisabled && "opacity-20"
                    }  bg-[#FD76571A] text-[#FD7657] px-3 py-2 rounded-md text-xs`}
                  >
                    Update tip
                  </button>
                </div>
                <div className="flex  items-center ">
                  {captureLoading ? (
                    <BtnLoader />
                  ) : (
                    <button
                      className="bg-[#FD76571A] text-[#FD7657] px-4 py-2 rounded-md text-xs"
                      onClick={capturePayment}
                    >
                      Capture Tip
                    </button>
                  )}
                </div>
              </div>
              }
              </div>


              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Status</h1>
                <select
                  name="roles"
                  className="text-[#] h-[40px] text-xs border rounded-lg border-[#F1F1F2] mt-1 px-4"
                  onChange={handleSelectChange}
                  value={orderDetails?.orderStatus}
                >
                  <option className="text-[]" value="PENDING">
                    Pending
                  </option>
                  <option className="text-[]" value="COMPLETED">
                    Completed
                  </option>
                  <option className="text-[]" value="VOIDED">
                    Voided
                  </option>
                </select>
              </div>

              {/* /////////// */}

              <h1 className="text-base text-[#1A1A1A] font-bold mt-4">
                Items Details
              </h1>

              <div className="h-[80px] overflow-y-scroll">
                <div className="mt-2">
                  <table className="table-auto w-full">
                    <thead className="mb-2">
                      <tr className="text-[#666666]">
                        <th className="text-left font-regular text-[12px]">
                          Name
                        </th>
                        <th className="text-center font-regular text-[12px]">
                          Quantity
                        </th>
                        <th className="text-center font-regular text-[12px]">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails?.items?.map((foodItem: any) => (
                        <tr key={foodItem?.correlationId}>
                          <td className="text-left font-regular text-[13px]">
                            {foodItem?.englishName}{" "}
                          </td>
                          <td className="text-center font-regular text-[13px]">
                            {foodItem?.quantity}
                          </td>
                          <td className="text-center font-regular text-[13px]">
                          $ {(Number(foodItem?.price)).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <h1 className="text-base text-[#1A1A1A] font-bold mt-6">
                Customer Details
              </h1>
              <div className="flex mt-3 items-center">
                <h1 className="text-[#666666] text-sm w-[200px]">Email</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerEmail === "null"
                    ? "-"
                    : orderDetails?.customerEmail || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">First Name</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerFirstName === "null"
                    ? "-"
                    : orderDetails?.customerFirstName || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Last Name</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerLastName === "null"
                    ? "-"
                    : orderDetails?.customerLastName || "-"}
                </h1>
              </div>
              <h1 className="text-base text-[#1A1A1A] font-bold mt-6">
                Payment Details
              </h1>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Brand</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.payment?.paymentMethodBrand || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Type</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.payment?.paymentMethodType || "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Last 4 digits
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.payment?.last4Digits
                    ? `**** **** **** ${orderDetails?.payment?.last4Digits}`
                    : "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Amount</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.payment?.paymentAmount
                    ? `$${orderDetails?.payment?.paymentAmount}`
                    : "-"}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Status</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {(orderDetails?.payment?.paymentStatus || "-").replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c : any) => c.toUpperCase())}
                </h1>
              </div>
              <div className="flex mt-3 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Payment Date
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.payment?.paymentDate
                    ? formatDateTime(orderDetails?.payment?.paymentDate)
                    : "-"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
