import React, { useState } from "react";

import { BtnLoader } from "src/Loader";
import { S3_BASE_URL } from "src/constants/endpoints";

import DeleteIcon from "../../../assests/images/dashboard/Button 2.png";
import DropIcon from "../../../assests/images/landing-page/Vector (1).png";
import RestuarantImg from "../../../assests/images/dashboard/default-thumbnail (1).jpg";
import EditIcon from "../../../assests/images/dashboard/edit.png";
import AddNewCustomerModal from "src/components/AddNewCustomerModal/AddNewCustomerModal";

const orderTypeMapping: any = {
  DINE_IN: "Dine In",
  PICK_UP: "Pick Up",
  DELIVERY: "Delivery",
};
const orderStatusMapping: any = {
  PENDING: "Pending",
  COMPLETED: "Completed",
  VOIDED: "Voided",
};
export const MyCartSection = ({
  toggleDropdown,
  dropdownOpen,
  orderType,
  editableOrder,
  toggleDropdownStatus,
  dropdownOpenStatus,
  orderStatus,
  setOrderStatus,
  setDropdownOpenStatus,
  formik,
  removeCardItem,
  isCheckOutEnabled,
  handleCheckoutCardCount,
  loading,
  selectedOrders,
  placeOrder,
  existingCustomer,
  getCustomers,
}: any) => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  return (
    <div
      className={`relative p-6 border border-[#F1F1F2] rounded-xl w-[40%] bg-white`}
    >
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-medium">My Cart</h1>
        <div className="flex items-center gap-x-2">
          <div className="relative my-7">
            <button
              type="button"
              onClick={toggleDropdown}
              className={`w-full py-3 pl-4 pr-5 flex items-center justify-between text-xs text-[#9B9B9B] border rounded-lg border-[#F1F1F2] ${
                dropdownOpen ? "border-[#f97658] shadow-md" : "border-gray-300"
              } rounded-md text-left focus:outline-none`}
            >
              {orderTypeMapping[
                (orderType || editableOrder?.orderType) as any
              ] || "Order Type"}
            </button>
          </div>
          {editableOrder?.correlationId && (
            <div className="relative my-7">
              <button
                type="button"
                onClick={toggleDropdownStatus}
                className={`w-full py-3 pl-4 pr-5 flex items-center justify-between text-xs text-[#9B9B9B] border rounded-lg border-[#F1F1F2] ${
                  dropdownOpenStatus
                    ? "border-[#f97658] shadow-md"
                    : "border-gray-300"
                } rounded-md text-left focus:outline-none`}
              >
                {orderStatusMapping[
                  (orderStatus || editableOrder?.orderStatus) as any
                ] || "Order Status"}
                <img className="h-[6px] w-[9px] ml-3" src={DropIcon} alt="" />
              </button>
              {dropdownOpenStatus && (
                <div className="absolute z-10 mt-1 w-full bg-white border  rounded-lg border-[#F1F1F2] shadow-lg">
                  <ul className="py-1">
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F]  ${
                        orderStatus === "PENDING" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderStatus("PENDING");
                        setDropdownOpenStatus(false);
                      }}
                    >
                      Pending
                    </li>
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F] ${
                        orderStatus === "COMPLETED" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderStatus("COMPLETED");
                        setDropdownOpenStatus(false);
                      }}
                    >
                      Completed
                    </li>
                    <li
                      className={`text-xs px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F] ${
                        orderStatus === "VOIDED" ? "bg-[#FD76571A]" : ""
                      }`}
                      onClick={() => {
                        setOrderStatus("VOIDED");
                        setDropdownOpenStatus(false);
                      }}
                    >
                      Voided
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {editableOrder && editableOrder?.orderType !== "DINE_IN" ? (
        <>
          <div>
            {(editableOrder?.tableNumber || orderType === "DINE_IN") && (
              <>
                <div className="flex justify-between items-center mt-4">
                  <h1 className="font-normal text-sm text-[#666666]">Table</h1>
                  <input
                    id="tableNumber"
                    name="tableNumber"
                    value={formik.values.tableNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    readOnly
                    placeholder="Enter table number"
                    className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-[140px]"
                  />
                </div>
              </>
            )}
            {orderType !== "DINE_IN" && (
              <>
                <div className="flex items-center gap-x-2">
                  <h1 className="font-medium text-md ml-[2px]">Customer</h1>
                </div>
                <div className="flex items-center gap-x-2 mt-3">
                  <div className="flex w-full flex-col">
                    <div className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1">
                      <h1>
                        {formik.values.customerFirstName}{" "}
                        {formik.values.customerLastName}
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center gap-x-2 mt-3">
              <div className="flex w-full flex-col ">
                <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                  Server First Name
                </h1>
                <input
                  id="serverFirstName"
                  name="serverFirstName"
                  value={formik.values.serverFirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  readOnly
                  placeholder=""
                  className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
                />
                {formik.touched.serverFirstName &&
                formik.errors.serverFirstName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.serverFirstName}
                  </div>
                ) : null}
              </div>
              <div className="flex w-full flex-col ">
                <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                  Server last Name
                </h1>
                <input
                  id="serverLastName"
                  name="serverLastName"
                  value={formik.values.serverLastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  readOnly
                  placeholder=""
                  className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
                />
                {formik.touched.serverLastName &&
                formik.errors.serverLastName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.serverLastName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col mt-2">
              <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                Customer Note
              </h1>
              <textarea
                id="customerNote"
                name="customerNote"
                value={formik.values.customerNote}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                readOnly
                placeholder=""
                className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
              />
            </div>
            <div className="overflow-y-scroll">
              {selectedOrders?.map((item: any, parentIndex: number) => {
                return (
                  <div
                    className="mt-4 flex gap-x-4 shadow-sm rounded-xl px-2"
                    key={parentIndex}
                  >
                    <div className="relative w-[61px] h-[61px] rounded-full border  mt-2 border-[#F1F1F2]">
                      <img
                        className="absolute object-cover h-full w-full rounded-full"
                        src={
                          item?.bucketKeyName
                            ? item?.bucketKeyName?.includes("blob")
                              ? item?.bucketKeyName
                              : S3_BASE_URL + item?.bucketKeyName
                            : RestuarantImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="border-b border-[#EBF5FF] pb-3 w-[71%]">
                      <div className="flex items-center justify-between">
                        <h1 className="text-base mt-2 font-medium text-[#252525]">
                          {item?.englishName}
                        </h1>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex w-[90px] justify-between items-center mt-4">
                          <button
                            className="bg-[#f6f6f6] px-3 rounded-md"
                            disabled={item?.count === 1}
                          >
                            <h1 className="">-</h1>
                          </button>
                          <div>
                            <h1 className="text-sm">{item?.count}</h1>
                          </div>
                          <button className="bg-[#f6f6f6] px-3 rounded-md">
                            <h1>+</h1>
                          </button>
                        </div>

                        <div className="flex gap-x-3 items-center">
                          <h1 className="text-xs text-[#252525] mt-4">
                            ${" "}
                            {item?.isEditCase
                              ? (
                                  (item?.totalElementPrice || 0) * item?.count
                                )?.toFixed(2)
                              : (
                                  (item?.totalElementPrice || 0) * item?.count +
                                    (item?.count || 1) * +item.price || 0
                                ).toFixed(2)}
                          </h1>
                        </div>
                      </div>

                      {item?.itemOptions?.map((option: any) => {
                        return (
                          <div key={option?.correlationId}>
                            {option?.isOptionSelected && (
                              <div className="mt-3 flex items-center justify-between w-full">
                                <h1 className="text-sm font-medium">
                                  {option.name}{" "}
                                </h1>
                              </div>
                            )}
                            {option?.itemOptionElements?.map((element: any) => {
                              return element?.isSelected &&
                                element?.count === 1 ? (
                                <div
                                  key={element?.correlationId}
                                  className="flex mt-[4px] items-center justify-between w-full"
                                >
                                  <h1 className="text-xs text-[#666666]">
                                    {element?.name}{" "}
                                  </h1>
                                  <h1 className="text-xs text-[#666666]">
                                    ${element?.price}{" "}
                                  </h1>
                                </div>
                              ) : (
                                Array.from(Array(element?.count).keys())?.map(
                                  (item: any) => {
                                    return (
                                      element?.isSelected && (
                                        <div
                                          key={element?.correlationId}
                                          className="flex mt-[4px] items-center justify-between w-full"
                                        >
                                          <h1 className="text-xs text-[#666666]">
                                            {element?.name}{" "}
                                          </h1>
                                          <h1 className="text-xs text-[#666666]">
                                            ${element?.price}{" "}
                                          </h1>
                                        </div>
                                      )
                                    );
                                  }
                                )
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {(formik.values.tableNumber || orderType === "DINE_IN") && (
              <>
                <div className="flex justify-between items-center mt-4">
                  <h1 className="font-normal text-sm text-[#666666]">Table</h1>
                  <input
                    id="tableNumber"
                    name="tableNumber"
                    value={formik.values.tableNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    placeholder=""
                    className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-[140px]"
                  />
                </div>
                {formik.touched.tableNumber && formik.errors.tableNumber ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.tableNumber}
                  </div>
                ) : null}
              </>
            )}
            {orderType !== "DINE_IN" && (
              <>
                <div className="flex items-center gap-x-2">
                  <h1 className="font-medium text-md ml-[2px]">Customer</h1>
                  <img
                    className="cursor-pointer h-4 w-4"
                    src={EditIcon}
                    alt="Edit Icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAddCustomerModal(true);
                    }}
                  />
                </div>

                <div className="flex items-center gap-x-2 mt-3">
                  <div className="flex w-full flex-col">
                    <div className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1">
                      <h1>
                        {formik.values.customerFirstName}{" "}
                        {formik.values.customerLastName}
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center gap-x-2 mt-3">
              <div className="flex w-full flex-col ">
                <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                  Server First Name
                </h1>
                <input
                  id="serverFirstName"
                  name="serverFirstName"
                  value={formik.values.serverFirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  placeholder=""
                  className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
                />
                {formik.touched.serverFirstName &&
                formik.errors.serverFirstName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.serverFirstName}
                  </div>
                ) : null}
              </div>
              <div className="flex w-full flex-col ">
                <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                  Server last Name
                </h1>
                <input
                  id="serverLastName"
                  name="serverLastName"
                  value={formik.values.serverLastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  placeholder=""
                  className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
                />
                {formik.touched.serverLastName &&
                formik.errors.serverLastName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.serverLastName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col mt-2">
              <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                Customer Note
              </h1>
              <textarea
                id="customerNote"
                name="customerNote"
                value={formik.values.customerNote}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder=""
                className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
              />
            </div>
            <div className="overflow-y-scroll">
              {selectedOrders?.map((item: any, parentIndex: number) => {
                return (
                  <div
                    className="mt-4 flex gap-x-4 shadow-sm rounded-xl px-2"
                    key={parentIndex}
                  >
                    <div className="relative w-[61px] h-[61px] rounded-full border  mt-2 border-[#F1F1F2]">
                      <img
                        className="absolute object-cover h-full w-full rounded-full"
                        src={
                          item?.bucketKeyName
                            ? item?.bucketKeyName?.includes("blob")
                              ? item?.bucketKeyName
                              : S3_BASE_URL + item?.bucketKeyName
                            : RestuarantImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="border-b border-[#EBF5FF] pb-3 w-[71%]">
                      <div className="flex items-center justify-between w-full">
                        <h1 className="text-base mt-2 font-medium text-[#252525] max-w-[160px]">
                          {item?.englishName}
                        </h1>
                        <div onClick={() => removeCardItem(item, parentIndex)}>
                          <img
                            className="w-[30px] h-[30px] mt-2 cursor-pointer"
                            src={DeleteIcon}
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex w-[90px] justify-between items-center mt-4">
                          <button
                            className="bg-[#f6f6f6] px-3 rounded-md"
                            disabled={item?.count === 1}
                            onClick={() =>
                              handleCheckoutCardCount(
                                item,
                                parentIndex,
                                item?.count - 1
                              )
                            }
                          >
                            <h1 className="">-</h1>
                          </button>
                          <div>
                            <h1 className="text-sm">{item?.count}</h1>
                          </div>
                          <button
                            className="bg-[#f6f6f6] px-3 rounded-md"
                            onClick={() =>
                              handleCheckoutCardCount(
                                item,
                                parentIndex,
                                item?.count + 1
                              )
                            }
                          >
                            <h1>+</h1>
                          </button>
                        </div>

                        <div className="flex gap-x-3 items-center">
                          <h1 className="text-xs text-[#252525] mt-4">
                            ${" "}
                            {item?.isEditCase
                              ? (
                                  (item?.totalElementPrice || 0) * item?.count
                                )?.toFixed(2)
                              : (
                                  (item?.totalElementPrice || 0) * item?.count +
                                    (item?.count || 1) * +item.price || 0
                                ).toFixed(2)}
                          </h1>
                        </div>
                      </div>

                      {item?.itemOptions?.map((option: any) => {
                        return (
                          <div key={option?.correlationId}>
                            {option?.isOptionSelected && (
                              <div className="mt-3 flex items-center justify-between w-full">
                                <h1 className="text-sm font-medium">
                                  {option.name}{" "}
                                </h1>
                              </div>
                            )}
                            {option?.itemOptionElements?.map((element: any) => {
                              return element?.isSelected &&
                                element?.count === 1 ? (
                                <div
                                  key={element?.correlationId}
                                  className="flex mt-[4px] items-center justify-between w-full"
                                >
                                  <h1 className="text-xs text-[#666666]">
                                    {element?.name}{" "}
                                  </h1>
                                  <h1 className="text-xs text-[#666666]">
                                    ${element?.price}{" "}
                                  </h1>
                                </div>
                              ) : (
                                Array.from(Array(element?.count).keys())?.map(
                                  (item: any) => {
                                    return (
                                      element?.isSelected && (
                                        <div
                                          key={element?.correlationId}
                                          className="flex mt-[4px] items-center justify-between w-full"
                                        >
                                          <h1 className="text-xs text-[#666666]">
                                            {element?.name}{" "}
                                          </h1>
                                          <h1 className="text-xs text-[#666666]">
                                            ${element?.price}{" "}
                                          </h1>
                                        </div>
                                      )
                                    );
                                  }
                                )
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-x-2 justify-center mt-4 py-4">
              {orderType === "DINE_IN" ||
              editableOrder?.tableNumber ||
              formik.values.tableNumber ? (
                <></>
              ) : (
                <>
                  {loading ? (
          
          <div className="flex justify-center items-center w-full">
          <BtnLoader />
        </div>

                  ) : (
                    <button
                      className={`${!isCheckOutEnabled && "opacity-20"} ${
                        orderType !== "DINE_IN" ? "w-[100%]" : "w-[42%]"
                      }  text-sm text-white h-[40px] rounded-lg bg-[#FD7657]`}
                      disabled={!isCheckOutEnabled && !orderType}
                      onClick={placeOrder}
                    >
                      Checkout
                    </button>
                  )}
                </>
              )}
                <>
                  {editableOrder?.orderType !== "DINE_IN" &&
                  orderType !== "DINE_IN" ? (
                    <></>
                  ) : (
                    <button
                      className={`${!isCheckOutEnabled && "opacity-20"} ${
                        orderType === "DINE_IN" ||
                        editableOrder?.tableNumber ||
                        formik.values.tableNumber
                          ? "w-[100%]"
                          : "w-[42%]"
                      }   text-sm text-white h-[40px] rounded-lg bg-[#FD7657]`}
                      onClick={placeOrder}
                      disabled={!isCheckOutEnabled}
                    >
                      {editableOrder?.correlationId
                        ? "Update Order"
                        : "Place Order"}
                    </button>
                  )}
                </>

            </div>
          </div>
        </>
      )}

      {showAddCustomerModal && (
        <AddNewCustomerModal
          CustomerOrderDetail={editableOrder}
          existingCustomer={existingCustomer}
          onClose={() => setShowAddCustomerModal(false)}
          getCustomers={getCustomers}
        />
      )}
    </div>
  );
};
