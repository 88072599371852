import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authSlice  from './features/auth-slice';
import restaurantSlice from './features/restuarant-slice';
import employeeSlice from './features/employee-slice';
import categorySlice from './features/category-slice';
import comboSlice from './features/combo-slice';
import restaurantTableSlice from './features/restuarant-table-slice';
import categoryItemSlice from './features/category-item-slice';
import cartsOrderSlice from './features/carts-order';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { notificationsSlice } from './features/notification-slice';

const rootReducer = combineReducers({
    auth: authSlice,
    restaurant: restaurantSlice,
    employee: employeeSlice,
    category: categorySlice,
    restaurantTable: restaurantTableSlice,
    categoryItem: categoryItemSlice,
    combo: comboSlice,
    cartsOrder: cartsOrderSlice,
    notifications : notificationsSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['notifications'], 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store);


export {store, persistor};