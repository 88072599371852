import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "src/hooks/useIdleTimer";
import { LoginExpireModal } from "src/components/LoginExpireModal/LoginExpiremodal";
import { useDispatch, useSelector } from "react-redux";
import ShowTokenRefreshModal from "src/components/ShowTokenRefreshModal/ShowTokenRefreshModal";
import DeleteRestaurant from "src/components/DeleteRestaurant/DeleteRestaurant";
import { useAuth } from "src/contexts/auth-provider";
import DeleteCategory from "src/components/DeleteRestaurant/DeleteCategory";
import DeleteCategoryItem from "src/components/DeleteRestaurant/DeleteCategoryItem";
import DeleteTable from "src/components/DeleteRestaurant/DeleteTable";
import DeleteCombo from "src/components/DeleteRestaurant/DeleteCombo";
import DeleteEmployee from "src/components/DeleteRestaurant/DeleteEmployee";
import { useProcessMonitoring } from "src/hooks/useProcessMonitoring";
// import { set_sync_notification } from "src/store/features/notification-slice";

export const DefaultLayout = () => {
  const { hasRestaurant } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(
    window.innerWidth <= 768 ? false : true
  );
  const {notifications, isSync}  = useSelector((state: any) => state.notifications);
  const { startMonitoring, stopMonitoring, isIncoming, setIsincoming} = useProcessMonitoring();
  const [pageTitle, setPageTitle]: any = useState("Dashboard");
  const { pathname } = useLocation();
  const { user, token } = useAuth();
  const auth = useSelector((state: any) => state.auth);
  const { hasStoreId } = useSelector((state: any) => state.restaurant);

  const { hasCategoryId } = useSelector((state: any) => state.category);

  const { hasCategoryItemId } = useSelector((state: any) => state.categoryItem);

  const { hasTableId } = useSelector((state: any) => state.restaurantTable);
  const { hasComboId } = useSelector((state: any) => state.combo);
  const { hasEmployeeId } = useSelector((state: any) => state.employee);
  const { correlationId } = hasRestaurant || {};
  const dispatch = useDispatch()
  const restaurantNotifications = notifications?.[hasRestaurant?.correlationId]


  console.log(restaurantNotifications, notifications, 'LAYOUT NOTIFY')

  useIdleTimer();

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth <= 768 && setSidebarOpen(false);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!correlationId) {
      stopMonitoring();
      // dispatch(set_sync_notification(false));
      return;
    }
    startMonitoring(correlationId, token, dispatch, restaurantNotifications, isSync);

    return () => {
      stopMonitoring();
      // dispatch(set_sync_notification(false));
    };
  }, [startMonitoring, correlationId, token]);

  const hideLayout = [
    "/create-restaurant",
    "/upload-image",
    "/plans",
    "/stripe-connection",
    "/success",
    "/cancel",
    "/loginexpair",
    "/create-new-order",
  ].includes(pathname);


  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark h-full">
      {auth.showExpiryLoginModal && <LoginExpireModal />}
      {auth.isShowTokenRefreshModal && <ShowTokenRefreshModal />}

      {hasStoreId && <DeleteRestaurant restaurantId={hasStoreId} />}

      {hasCategoryId && <DeleteCategory categoryId={hasCategoryId} />}

      {hasCategoryItemId && <DeleteCategoryItem itemId={hasCategoryItemId} />}

      {hasTableId && <DeleteTable tableId={hasTableId} />}

      {hasComboId && <DeleteCombo comboId={hasComboId} />}
      {hasEmployeeId && <DeleteEmployee employeeId={hasEmployeeId} />}

      {/* Page Wrapper Start */}
      <div className="flex h-full overflow-hidden">
        {/* Sidebar Start */}
        {!hideLayout && (
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setPageTitle={setPageTitle}
          />
        )}
        {/* Sidebar End */}

        {/*  Content Area Start  */}
        <div className="relative flex flex-1 bg-[#FAFAFA] flex-col overflow-y-auto">
          {/* Header Start */}

          {!hideLayout && (
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              user={user}
              pageTitle={pageTitle}
              notification={restaurantNotifications?.notifications}
              isIncoming={isIncoming}
              setIsincoming={setIsincoming}
              
            />
          )}
          {/* Header End */}

          {/* Main Content Start  */}
          <main>
            <div
              className={
                !hideLayout
                  ? "mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10"
                  : ""
              }
            >
              {/* {children} */}
              <Outlet />
            </div>
          </main>
          {/* Main Content  */}
        </div>
        {/* Content Area  */}
      </div>
      {/* Page Wrapper End */}
    </div>
  );
};
