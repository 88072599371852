import { useEffect, useState } from "react";
import AreaChartBar from "src/components/AreaChart/AreaChart";
import QrCodeIcon from "../../assests/images/dashboard/Union.png";
import DownloadIcon from "../../assests/images/dashboard/Container.png";
import UserIcon from "../../assests/images/dashboard/Switch container.png";
import Dropdown from "src/components/Dropdown/Dropdown";
import { useAuth } from "src/contexts/auth-provider";
import { Link, useNavigate } from "react-router-dom";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import { useDispatch, useSelector } from "react-redux";
import RestaurantCard from "src/components/RestaurantCard/RestaurantCard";
import { BtnLoader } from "src/Loader/index";
import {
  delete_restaurant_modal,
  set_restaurants,
} from "src/store/features/restuarant-slice";
import QrCodeModal from "src/components/QrCodeModal/QrCodeModal";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import {
  GET_ALL_RESTAURANTS,
  GET_CUSTOMER_ORDERS,
  GET_EMPLOYEE,
  GET_GRAPH_DATA_FOR_OWNER,
  GET_SALES_HISTORY,
} from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { formatDate } from "src/utils/date";
import moment from "moment";
import { AvailbleFeatures } from "src/utils/isAllowedFeature";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant, permissions, user } = useAuth();

  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [orders, setOrders] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [employeeLoader, setEmployeeLoader] = useState(false);
  const { restaurants } = useSelector((state: any) => state.restaurant);
  const [totalIncomeChartLoader, setTotalIncomeChartLoader] =
    useState<boolean>(false);
  const [totalOrdersChartLoader, setTotalOrdersChartLoader] =
    useState<boolean>(false);
  const [avgCheckChartLoader, setAvgCheckChartLoader] =
    useState<boolean>(false);
  const [totalIncomeChart, setTotalIncomeChart] = useState<any>({});
  const [totalOrdersChart, setTotalOrdersChart] = useState<any>({});
  const [avgCheckChart, setAvgCheckChart] = useState<any>({});

  const hasReportingAndAnalytics = user?.storeUser?.stores.find((store: any) =>
    store.allowedFeatures.includes("Reporting and analytics")
  );

  const handleRestaurantClick = (restaurantId: string) => {
    dispatch(delete_restaurant_modal(restaurantId));
  };

  useEffect(() => {
    if (hasRestaurant) {
      const getOrders = async () => {
        setLoading(true);
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_CUSTOMER_ORDERS,
            variables: {
              storeCorrelationId: hasRestaurant?.correlationId,
              last: 3,
            },
          });
          setOrders(
            data?.data?.customerOrdersByStoreCorrelationId?.edges || []
          );
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
      getOrders();
    }
  }, [axBe, hasRestaurant]);

  useEffect(() => {
    if (hasRestaurant && permissions[AvailbleFeatures.TeamManagement]) {
      const getEmployees = async () => {
        setEmployeeLoader(true);
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_EMPLOYEE,
            variables: {
              storeCorrelationId: hasRestaurant.correlationId,
            },
          });
          setEmployees(data?.data?.storeEmployeesByStoreCorrelationId || []);
          setEmployeeLoader(false);
        } catch (error) {
          console.error(error);
          setEmployeeLoader(false);
        }
      };
      getEmployees();
    }
  }, [axBe, hasRestaurant]);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axBe.post(GRAPHQL_ENDPOINT, {
          query: GET_ALL_RESTAURANTS,
        });
        const fetchedRestaurants = response.data?.data?.loggedInUserStores;
        if (Array.isArray(fetchedRestaurants)) {
          dispatch(set_restaurants({ restaurants: fetchedRestaurants }));
        } else {
          console.error("Unexpected result structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchRestaurants();
  }, [axBe, dispatch]);

  const fetchTotalIncome = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (hasRestaurant) {
      const getSalesHistory = async () => {
        setTotalIncomeChartLoader(true);
        const variables = {
          getSalesHistoryInput: {
            storeCorrelationId: hasRestaurant.correlationId,
            requestedHours: hours,
          },
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_SALES_HISTORY,
            variables: variables,
          });
          const tempData = data?.data?.salesHistoryByStoreCorrelationId;

          const areaChartData = {
            graphKeys: [{ name: "Price", strokeColor: "#82ca9d" }],
            totalPrice: tempData.reduce(
              (acc: number, next: any) => acc + (next.storeTotalPrice || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: number) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");

                return {
                  Price: item.storeTotalPrice,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setTotalIncomeChart(areaChartData);
          setTotalIncomeChartLoader(false);
        } catch (error) {
          console.error(error);
          setTotalIncomeChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  const fetchTotalOrders = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (hasRestaurant) {
      const getSalesHistory = async () => {
        setTotalOrdersChartLoader(true);
        const variables = {
          getSalesHistoryInput: {
            storeCorrelationId: hasRestaurant.correlationId,
            requestedHours: hours,
          },
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_SALES_HISTORY,
            variables: variables,
          });
          const tempData = data?.data?.salesHistoryByStoreCorrelationId;
          const lineChartData = {
            graphKeys: [{ name: "Orders", strokeColor: "#B1E71D" }],
            totalOrder: tempData.reduce(
              (acc: number, next: any) => acc + (next.orderNumber || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: any) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");
                return {
                  Orders: item.orderNumber,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setTotalOrdersChart(lineChartData);
          setTotalOrdersChartLoader(false);
        } catch (error) {
          console.error(error);
          setTotalOrdersChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  const fetchAvgCheck = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (hasRestaurant) {
      const getSalesHistory = async () => {
        setAvgCheckChartLoader(true);
        const variables = {
          getSalesHistoryInput: {
            storeCorrelationId: hasRestaurant.correlationId,
            requestedHours: hours || 168,
          },
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_SALES_HISTORY,
            variables: variables,
          });
          const tempData = data?.data?.salesHistoryByStoreCorrelationId;
          const lineChartData = {
            graphKeys: [{ name: "Check", strokeColor: "#8569F6" }],
            avgCheck: tempData.reduce(
              (acc: number, next: any) =>
                acc + (next.averageOrderTotalPrice || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: number) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");
                return {
                  Check: item.averageOrderTotalPrice,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setAvgCheckChart(lineChartData);
          setAvgCheckChartLoader(false);
        } catch (error) {
          console.error(error);
          setAvgCheckChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  const getTimeForIndex = (tempData: any, index: any, time: any) => {
    const len = tempData?.length;
    const midIndex = Math.round(len / 2) - 1;

    if (index === 0 || index === len - 1) {
      return time;
    }

    if (len >= 3) {
      if (len % 2 === 0 && (index === midIndex || index === midIndex + 1)) {
        return time;
      }

      if (index === midIndex) {
        return time;
      }
    }

    return null;
  };

  const fetchTotalIncomeForOwner = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (!hasRestaurant) {
      const getSalesHistory = async () => {
        setTotalIncomeChartLoader(true);
        const variables = {
          requestedHours: hours,
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_GRAPH_DATA_FOR_OWNER,
            variables: variables,
          });
          const tempData = data?.data?.loggedInUserSalesHistory;
          const areaChartData = {
            graphKeys: [{ name: "Price", strokeColor: "#82ca9d" }],
            totalPrice: tempData.reduce(
              (acc: number, next: any) => acc + (next.storeUserTotalPrice || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: number) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");

                return {
                  Price: item.storeUserTotalPrice,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setTotalIncomeChart(areaChartData);
          setTotalIncomeChartLoader(false);
        } catch (error) {
          console.error(error);
          setTotalIncomeChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  const fetchTotalOrdersForOwner = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (!hasRestaurant) {
      const getSalesHistory = async () => {
        setTotalOrdersChartLoader(true);
        const variables = {
          requestedHours: hours,
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_GRAPH_DATA_FOR_OWNER,
            variables: variables,
          });
          const tempData = data?.data?.loggedInUserSalesHistory;
          const lineChartData = {
            graphKeys: [{ name: "Orders", strokeColor: "#B1E71D" }],
            totalOrder: tempData.reduce(
              (acc: number, next: any) => acc + (next.orderNumber || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: any) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");
                return {
                  Orders: item.orderNumber,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setTotalOrdersChart(lineChartData);
          setTotalOrdersChartLoader(false);
        } catch (error) {
          console.error(error);
          setTotalOrdersChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  const fetchAvgCheckForOwner = async (
    hours: any = 168,
    graphDropdownValue: string = "Last 7 Days"
  ) => {
    if (!hasRestaurant) {
      const getSalesHistory = async () => {
        setAvgCheckChartLoader(true);
        const variables = {
          requestedHours: hours || 168,
        };
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_GRAPH_DATA_FOR_OWNER,
            variables: variables,
          });
          const tempData = data?.data?.loggedInUserSalesHistory;
          const lineChartData = {
            graphKeys: [{ name: "Check", strokeColor: "#8569F6" }],
            avgCheck: tempData.reduce(
              (acc: number, next: any) =>
                acc + (next.averageOrderTotalPrice || 0),
              0
            ),
            data: tempData
              ?.map((item: any, index: number) => {
                const time =
                  graphDropdownValue === "Today"
                    ? moment(item?.bucket).format("hh:mm A")
                    : moment(item?.bucket).format("YYYY-MM-DD");
                return {
                  Check: item.averageOrderTotalPrice,
                  name: getTimeForIndex(tempData, index, time),
                };
              })
              .reverse(),
          };
          setAvgCheckChart(lineChartData);
          setAvgCheckChartLoader(false);
        } catch (error) {
          console.error(error);
          setAvgCheckChartLoader(false);
        }
      };
      getSalesHistory();
    }
  };

  useEffect(() => {
    if (permissions["Reporting and analytics"]) {
      fetchTotalIncome();
      fetchTotalOrders();
      fetchAvgCheck();
    }
  }, [permissions]);

  useEffect(() => {
    if (!hasRestaurant) {
      fetchTotalIncomeForOwner();
      fetchTotalOrdersForOwner();
      fetchAvgCheckForOwner();
    }
  }, []);

  return (
    <div className="h-full flex flex-col">
      <div>
        {!hasRestaurant ? (
          <></>
        ) : (
          <div className="flex justify-between items-center mt-4 mb-6">
            <div className="text-2xl font-semibold ml-3">
              {hasRestaurant?.name}
            </div>
            <div className="flex gap-3">
              <div className="flex justify-center">
                {permissions[AvailbleFeatures.DigitalMenuThroughQRCode] && (
                  <button
                    className="w-[200px] flex justify-center items-center gap-x-4 text-white h-[38px] rounded-xl bg-[#FD7657] duration-500"
                    onClick={() => setQrCodeModal(!qrCodeModal)}
                  >
                    <img
                      className="h-[15px] w-[15px]"
                      src={QrCodeIcon}
                      alt=""
                    />
                    Generate QR Code
                  </button>
                )}
              </div>

              <div className="flex justify-center">
                <button className="w-[200px] flex justify-center items-center gap-x-4 text-[#FD7657] h-[38px] rounded-xl bg-[#FD76571A] duration-500">
                  <img
                    className="h-[15px] w-[15px]"
                    src={DownloadIcon}
                    alt=""
                  />
                  Download Client
                </button>
              </div>
            </div>
          </div>
        )}
        {hasRestaurant ? (
          <>
            {permissions[AvailbleFeatures.ReportingAndAnalytics] && (
              <div className="flex w-full gap-6">
                <div className="w-[33%]">
                  <div className="flex justify-between p-3 w-full">
                    <h1 className="font-semibold text-xl">Sales</h1>
                    <Dropdown fetchGraph={fetchTotalIncome} />
                  </div>

                  <div className="h-[347px]  rounded-2xl bg-white px-4 py-2 flex flex-col justify-center items-center">
                    {totalIncomeChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <>
                        <div className="flex items-center gap-x-2">
                          <p className="text-[#666666] text-sm my-4">
                            Sales Over Time:
                          </p>
                          <h1 className="text-xl">
                            $
                            <span>
                              {totalIncomeChart?.totalPrice?.toFixed(2)}
                            </span>
                          </h1>
                        </div>
                        <AreaChartBar
                          areaChartData={totalIncomeChart}
                          color={"#82ca9d"}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-[33%]">
                  <div className="flex justify-between p-3">
                    <h1 className="font-semibold text-xl">Orders</h1>
                    <Dropdown fetchGraph={fetchTotalOrders} />
                  </div>

                  <div className="h-[347px] rounded-2xl bg-white p-4 flex gap-4 flex-col justify-center items-center">
                    {totalOrdersChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-row gap-10 ml-4">
                          <div className="flex items-center gap-x-2">
                            <div className="flex justify-center items-center gap-x-1">
                              <p className="text-[#666666] text-sm ">
                                Orders Over Time:
                              </p>
                              <h1 className="">
                                <span className="text-xl">
                                  {totalOrdersChart.totalOrder}
                                </span>
                              </h1>
                            </div>
                          </div>
                        </div>
                        <AreaChartBar
                          areaChartData={totalOrdersChart}
                          color={"#B1E61E"}
                          isShowDollarSign={false}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-[33%]">
                  <div className="flex justify-between p-3">
                    <h1 className="font-semibold text-xl">Average Check</h1>
                    <Dropdown fetchGraph={fetchAvgCheck} />
                  </div>

                  <div className="h-[347px] rounded-2xl bg-white p-4 flex gap-4 flex-col justify-center items-center">
                    {avgCheckChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <AreaChartBar
                        areaChartData={avgCheckChart}
                        color={"#8569F6"}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {hasReportingAndAnalytics?.correlationId?.length > 0 ? (
              <div className="flex w-full gap-6">
                <div className="w-[33%]">
                  <div className="flex justify-between p-3 w-full">
                    <h1 className="font-semibold text-xl">Sales</h1>
                    <Dropdown fetchGraph={fetchTotalIncomeForOwner} />
                  </div>

                  <div className="h-[347px]  rounded-2xl bg-white px-4 py-2 flex flex-col justify-center items-center">
                    {totalIncomeChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <>
                        <div className="flex items-center gap-x-2">
                          <p className="text-[#666666] text-sm my-4">
                            Sales Over Time:
                          </p>
                          <h1 className="text-xl">
                            $
                            <span>
                              {totalIncomeChart?.totalPrice?.toFixed(2)}
                            </span>
                          </h1>
                        </div>
                        <AreaChartBar
                          areaChartData={totalIncomeChart}
                          color={"#82ca9d"}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-[33%]">
                  <div className="flex justify-between p-3">
                    <h1 className="font-semibold text-xl">Orders</h1>
                    <Dropdown fetchGraph={fetchTotalOrdersForOwner} />
                  </div>

                  <div className="h-[347px] rounded-2xl bg-white p-4 flex gap-4 flex-col justify-center items-center">
                    {totalOrdersChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-row gap-10 ml-4">
                          <div className="flex items-center gap-x-2">
                            <div className="flex justify-center items-center gap-x-1">
                              <p className="text-[#666666] text-sm ">
                                Orders Over Time:
                              </p>
                              <h1 className="">
                                <span className="text-xl">
                                  {totalOrdersChart.totalOrder}
                                </span>
                              </h1>
                            </div>
                          </div>
                        </div>
                        <AreaChartBar
                          areaChartData={totalOrdersChart}
                          color={"#B1E61E"}
                          isShowDollarSign={false}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-[33%]">
                  <div className="flex justify-between p-3">
                    <h1 className="font-semibold text-xl">Average Check</h1>
                    <Dropdown fetchGraph={fetchAvgCheckForOwner} />
                  </div>

                  <div className="h-[347px] rounded-2xl bg-white p-4 flex gap-4 flex-col justify-center items-center">
                    {avgCheckChartLoader ? (
                      <div className="flex justify-center items-center h-[330px]">
                        <BtnLoader />
                      </div>
                    ) : (
                      <AreaChartBar
                        areaChartData={avgCheckChart}
                        color={"#8569F6"}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      {!hasRestaurant ? (
        !restaurants?.length ? (
          <div className="mt-4">
            <div className="flex justify-between p-3">
              <h1 className="font-medium text-xl">My Restaurants</h1>
              <p className="text-orange cursor-pointer">View All</p>
            </div>
            <div className="h-[347px]  rounded-2xl bg-white p-6 flex justify-center items-center flex-col">
              <h1>You have no restaurants yet</h1>
              <p className="text-[#9B9B9B]">
                Click "Add new restaurants" to interact with it
              </p>
              <div className="flex justify-center my-4">
                <Link className="w-[100%]" to="/create-restaurant">
                  <button className="w-[200px] text-white h-[38px] rounded-xl bg-[#FD7657] hover:bg-white hover:text-orange duration-500">
                    &#65291; Add Restuarant
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between p-3 mt-4">
              <h1 className="font-semibold text-xl">My Restaurants</h1>
              <p
                onClick={() => navigate("/my-restaurants")}
                className="text-orange cursor-pointer"
              >
                View All
              </p>
            </div>
            <div className="flex gap-3">
              <div
                className="w-[10%] bg-white rounded-xl flex justify-center items-center cursor-pointer"
                onClick={() => navigate("/create-restaurant")}
              >
                <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
              </div>
              {restaurants?.slice(0, 3).map((restaurant: any, index: any) => (
                <RestaurantCard
                  isDashboard={true}
                  key={index}
                  restaurant={restaurant}
                  handleRestaurantClick={handleRestaurantClick}
                />
              ))}
            </div>
          </div>
        )
      ) : (
        <div className="flex w-full gap-8">
          <div className="w-[70%] mt-6">
            <div className="flex justify-between p-3">
              <h1 className="font-semibold text-xl">Recent Orders</h1>
              <p
                className="text-orange cursor-pointer"
                onClick={() =>
                  navigate(
                    `/my-restaurant/${hasRestaurant.correlationId}/orderManagement`
                  )
                }
              >
                View All
              </p>
            </div>
            {loading ? (
              <BtnLoader />
            ) : (
              <div className="rounded-2xl bg-white px-5 py-1">
                {orders && orders.length > 0 ? (
                  orders.map((item: any, index: any) => (
                    <div
                      key={item?.node?.correlationId}
                      className={`flex flex-col gap-y-4 pb-3 ${
                        index === orders.length - 1
                          ? ""
                          : "border-b border-[#EDEDED]"
                      }`}
                    >
                      <div className="flex justify-between ">
                        <div className="flex items-center gap-4 mt-4">
                          <h1 className="font-medium text-sm">
                            {item?.node?.correlationId}
                          </h1>
                          <span
                            className={`${
                              item?.node?.orderStatus?.toLowerCase() ===
                                "voided" && "bg-[#F144451A] text-[#F14445]"
                            } ${
                              item?.node?.orderStatus?.toLowerCase() ===
                                "completed" && "bg-[#3ACC481A] text-[#3ACC48]"
                            } ${
                              item?.node?.orderStatus?.toLowerCase() ===
                                "pending" && "bg-[#3A51CC1A] text-[#3A51CC]"
                            } rounded-[8px] py-[6px] px-3 text-xs `}
                          >
                            {item?.node?.orderStatus.charAt(0).toUpperCase() +
                              item?.node?.orderStatus.slice(1).toLowerCase() ||
                              "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-3 items-center">
                        <p className="text-[#252525] text-xs">
                          {item?.node?.customerEmail || "-"}
                        </p>
                        <span className="h-[2px] w-[2px] bg-[#2525251F] rounded-full p-[2px]"></span>
                        <p className="text-[#252525] text-xs">
                          ${item?.node?.totalPrice || "-"}
                        </p>
                        <span className="h-[2px] w-[2px] bg-[#2525251F] rounded-full p-[2px]"></span>
                        <p className="text-[#252525] text-xs">
                          {formatDate(item?.node?.createdAt) || "-"}
                        </p>
                        <span className="h-[2px] w-[2px] bg-[#2525251F] rounded-full p-[2px]"></span>
                        <p className="text-[#252525] text-xs">
                          {item?.node?.paid === true
                            ? "Paid"
                            : "Not paid" || "-"}
                        </p>
                        <span className="h-[2px] w-[2px] bg-[#2525251F] rounded-full p-[2px]"></span>
                        <p className="text-[#252525] text-xs">
                          {item?.node?.isGuest === false
                            ? "Not Guest"
                            : "Guest" || "-"}
                        </p>
                        <span className="h-[2px] w-[2px] bg-[#2525251F] rounded-full p-[2px]"></span>
                        <p className="text-[#252525] text-sm">
                          Table {item?.node?.tableNumber || " - "}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center h-[300px]">
                    <p className="text-center text-sm text-[#666666]">
                      You have no orders yet.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          {permissions[AvailbleFeatures.TeamManagement] && (
            <div className="w-[30%] mt-6">
              <div className="flex justify-between items-center p-3">
                <h1 className="font-semibold text-xl">Employees</h1>
                <p
                  onClick={() =>
                    navigate(
                      `/my-restaurant/${hasRestaurant.correlationId}/employee`
                    )
                  }
                  className="text-orange cursor-pointer"
                >
                  View All
                </p>
              </div>

              {employeeLoader ? (
                <BtnLoader />
              ) : (
                <div className="rounded-2xl bg-white p-6 flex gap-4 flex-col">
                  {employees && employees?.length > 0 ? (
                    employees?.slice(0, 4).map((employee: any, index) => (
                      <div className="flex gap-x-4" key={index}>
                        <div>
                          <img
                            className="h-[42px] w-[50px]"
                            src={UserIcon}
                            alt=""
                          />
                        </div>
                        <div
                          className={`${
                            index === 3 ? "" : "border-b border-[#EDEDED]"
                          } w-full pb-2`}
                        >
                          <h1 className="text-sm">
                            {employee?.firstName} {employee?.lastName}
                          </h1>
                          <h1 className="text-sm mt-1">
                            {employee?.roles[0]
                              .slice(14)
                              .charAt(0)
                              .toUpperCase() +
                              employee?.roles[0]
                                .slice(14)
                                .slice(1)
                                .toLowerCase()}
                          </h1>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center h-[260px]">
                      <p className="text-center text-sm text-[#666666]">
                        No employee found.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {qrCodeModal ? (
        <QrCodeModal
          restaurantId={hasRestaurant.correlationId}
          setQrCodeModal={() => setQrCodeModal(!qrCodeModal)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
