import { useState, useEffect, useRef, useCallback } from "react";
import SockJS from "sockjs-client";
import { Client, Stomp } from "@stomp/stompjs";
import { API_ENDPOINT } from "src/constants/endpoints";

interface MessagePayload {
  orderId: string;
  receiptType: string; // or other message structure
}

export const useWebSocketSender = () => {
  const [isSending, setIsSending] = useState(false);
  const stompClientRef = useRef<Client | any>();
  const baseUrl = new URL(API_ENDPOINT).origin;

  const startConnection = (token: string) => {
    const socket = new SockJS(`${baseUrl}/ws`);
    const stompClient = Stomp.over(socket);
    stompClient.connect({ Authorization: `Bearer ${token}` }, (frame: any) => {

      stompClientRef.current = stompClient;
      console.log("WebSocket connection established");
    });
  };

  const sendMessage = (destination: string, message: MessagePayload) => {
    if (stompClientRef.current) {
      setIsSending(true);
      stompClientRef.current.send(destination, {}, JSON.stringify(message));
      setIsSending(false);
      console.log("Message sent:", message);
    } else {
      console.error("WebSocket client is not connected.");
    }
  };

  const stopConnection = useCallback(() => {
    if (stompClientRef.current) {
      stompClientRef.current.deactivate();
      stompClientRef.current = null;
      console.log("WebSocket connection closed");
    }
  }, []);

  useEffect(() => {
    return () => {
      stopConnection();
    };
  }, [stopConnection]);

  return { isSending, startConnection, stopConnection, sendMessage };
};
