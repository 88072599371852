import React, { useEffect, useState } from "react";
import CrossIcon from "../../assests/images/dashboard/Vector 3.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { ADD_CUSTOMER, UPDATE_CUSTOMER } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { BtnLoader } from "src/Loader";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import State from "../State/State";

const AddNewCustomerModal = ({
  onClose,
  customerData,
  CustomerOrderDetail,
  existingCustomer,
  orderType,
  getCustomers,
}: any) => {

  const { axBe } = useAxiosInterceptor();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      address: Yup.object({
        addressLine1: Yup.string().required("Street is required"),
        city: Yup.string().required("City is required"),
        zipCode: Yup.string().required("Zip Code is required"),
        state: Yup.string().required("State is required"),
      }).required("Address is required"),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (customerData) {
      formik.setFieldValue("firstName", customerData?.firstName);
      formik.setFieldValue("lastName", customerData?.lastName);
      formik.setFieldValue("phoneNumber", customerData?.phoneNumber);
      formik.setFieldValue("email", customerData?.email);
      formik.setFieldValue("address", customerData?.address);
    } else if (CustomerOrderDetail) {
      formik.setFieldValue("firstName", CustomerOrderDetail?.customerFirstName);
      formik.setFieldValue("lastName", CustomerOrderDetail?.customerLastName);
      formik.setFieldValue(
        "phoneNumber",
        CustomerOrderDetail?.customerPhoneNumber
      );
      formik.setFieldValue("email", CustomerOrderDetail?.customerEmail);
      formik.setFieldValue(
        "address.addressLine1",
        `${
          CustomerOrderDetail?.customersDetails?.[0]?.address.addressLine1 || ""
        }`
      );
      formik.setFieldValue(
        "address.addressLine2",
        `${
          CustomerOrderDetail?.customersDetails?.[0]?.address.addressLine2 || ""
        }`
      );
      formik.setFieldValue(
        "address.city",
        `${CustomerOrderDetail?.customersDetails?.[0]?.address.city || ""}`
      );
      formik.setFieldValue(
        "address.state",
        `${CustomerOrderDetail?.customersDetails?.[0]?.address.state || ""}`
      );
      formik.setFieldValue(
        "address.zipCode",
        `${CustomerOrderDetail?.customersDetails?.[0]?.address.zipCode || ""}`
      );
    } else if (existingCustomer) {
      formik.setFieldValue("firstName", existingCustomer?.firstName);
      formik.setFieldValue("lastName", existingCustomer?.lastName);
      formik.setFieldValue("phoneNumber", existingCustomer?.phoneNumber);
      formik.setFieldValue("email", existingCustomer?.email);
      formik.setFieldValue(
        "address.addressLine1",
        `${existingCustomer?.address?.addressLine1 || ""}`
      );
      formik.setFieldValue(
        "address.addressLine2",
        `${existingCustomer?.address?.addressLine2 || ""}`
      );
      formik.setFieldValue(
        "address.city",
        `${existingCustomer?.address?.city || ""}`
      );
      formik.setFieldValue(
        "address.state",
        `${existingCustomer?.address?.state || ""}`
      );
      formik.setFieldValue(
        "address.zipCode",
        `${existingCustomer?.address?.zipCode || ""}`
      );
    }
  }, [customerData, existingCustomer]);

  const AddCustomer = async () => {
    setLoading(true);
    try {
      const variables: any = {
        createCustomerInput: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          phoneNumber: formik.values.phoneNumber.toString(),
          address: formik.values.address,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_CUSTOMER,
        variables: variables,
      });
      if (data?.data?.createCustomer?.correlationId) {
        successToast("New Customer Added!");
        onClose();
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
    } finally {
      setLoading(false);
    }
  };

  const UpdateCustomer = async () => {
    setLoading(true);
    try {
      const variables: any = {
        updateCustomerInput: {
          customerCorrelationId:
            customerData?.correlationId ||
            CustomerOrderDetail?.correlationId ||
            existingCustomer?.correlationId,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          phoneNumber: formik.values.phoneNumber.toString(),
          address: formik.values.address,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_CUSTOMER,
        variables: variables,
      });
      if (data?.data?.updateCustomer?.correlationId) {
        successToast("Customer Edited!");
        onClose();
        // getCustomers()
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto ">
        <div className=" flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" w-[529px] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex flex-col">
              <div className="rounded-2xl bg-white p-6">
                <>
                  <div className="flex justify-between">
                    <h1 className="font-semibold text-lg">
                      {customerData
                        ? "Edit"
                        : existingCustomer
                        ? "Edit"
                        : CustomerOrderDetail
                        ? "Edit"
                        : "Add New"}{" "}
                      Customer
                    </h1>
                    <img
                      className="w-[12px] h-[12px] cursor-pointer"
                      src={CrossIcon}
                      alt=""
                      onClick={onClose}
                    />
                  </div>
                  <div className="flex justify-between gap-x-2 w-full mt-3">
                    <div className="mt-3 w-full">
                      <span className="text-[#9B9B9B] text-sm">
                        First Name *
                      </span>
                      <input
                        className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder=""
                        value={formik.values?.firstName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text-orange text-xs mt-2">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-3 w-full">
                      <span className="text-[#9B9B9B] text-sm">Last Name</span>
                      <input
                        className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder=""
                        value={formik.values?.lastName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="text-orange text-xs mt-2">
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-between gap-x-2 w-full">
                    <div className="mt-3 w-full">
                      <span className="text-[#9B9B9B] text-sm">
                        Phone Number
                      </span>
                      <input
                        className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder=""
                        value={formik.values?.phoneNumber}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="text-orange text-xs mt-2">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-3 w-full">
                      <span className="text-[#9B9B9B] text-sm">Email *</span>
                      <input
                        className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="email"
                        name="email"
                        id="email"
                        placeholder=""
                        value={formik.values?.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-orange text-xs mt-2">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-3">
                    <span className="text-[#9B9B9B] text-sm">Address Line 1</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="address.addressLine1"
                      id="address.addressLine1"
                      placeholder=""
                      value={formik.values?.address?.addressLine1}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.address?.addressLine1 &&
                    formik.errors.address?.addressLine1 ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.address?.addressLine1}
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <span className="text-[#9B9B9B] text-sm">Address Line 2</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="address.addressLine2"
                      id="address.addressLine2"
                      placeholder=""
                      value={formik.values?.address?.addressLine2}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="flex w-full gap-x-2 items-center">
                  <div className="mt-3 w-full">
                    <span className="text-[#9B9B9B] text-sm">City</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="address.city"
                      id="address.city"
                      placeholder=""
                      value={formik.values.address?.city}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.address?.city &&
                    formik.errors.address?.city ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.address.city}
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-3 w-full">
                    <span className="text-[#9B9B9B] text-sm">State</span>
                    {/* <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="address.state"
                      id="address.state"
                      placeholder=""
                      value={formik.values.address?.state}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.address?.state &&
                    formik.errors.address?.state ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.address?.state}
                      </div>
                    ) : null} */}
                    <State
                    value={formik.values.address?.state}
                    // onChange={formik.handleChange}
                    onChange={(e: any) => {formik.setFieldValue("address.state", e.target.value);}}
                    onBlur={formik.handleBlur}
                    error={formik}
                    name={"address.state"}
                  />
                  </div>
                  </div>


                  <div className="mt-3">
                    <span className="text-[#9B9B9B] text-sm">Zip Code</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="address.zipCode"
                      id="address.zipCode"
                      placeholder=""
                      value={formik.values?.address?.zipCode}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.address?.zipCode &&
                    formik.errors.address?.zipCode ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.address?.zipCode}
                      </div>
                    ) : null}
                  </div>
                </>
              </div>
              <div className="flex justify-end my-4 mr-6">
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button
                    className={`${
                      isDisabled && "opacity-20"
                    } text-sm duration-500 w-[100px] text-white h-[35px] rounded-md bg-[#FD7657]`}
                    disabled={isDisabled}
                    onClick={
                      customerData
                        ? UpdateCustomer
                        : existingCustomer
                        ? UpdateCustomer
                        : CustomerOrderDetail
                        ? UpdateCustomer
                        : AddCustomer
                    }
                  >
                    {customerData
                      ? "Update"
                      : existingCustomer
                      ? "Update"
                      : CustomerOrderDetail
                      ? "Update"
                      : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCustomerModal;
