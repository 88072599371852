import { useEffect, useMemo, useRef, useState } from "react";
import { formatDateTime } from "src/utils/date";
import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import PayBillAmountModal from "../PayBillsAmountModal/PayBillsAmountModal";
import {
  itemTotalTaxCalc,
  itemsTotalPriceCalc,
} from "src/utils/order-price-calculation";
import { useReactToPrint } from "react-to-print";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { API_ENDPOINT, GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { UPDATE_CUSTOMER_ORDER } from "src/constants/mutations";
import { GET_PERCENT_OFF } from "src/constants/queries";
import { BtnLoader } from "src/Loader";
import { failureToast } from "src/utils/toasts";

const stripePromise = loadStripe(
  "pk_test_51NHw8jKjnAk1QjV6nYywO8puBRapptfFfExYARFCCMOypQRI7gQCLh9XJVh3r3ywFCmSMqMl3ceHL382UNcIifsl00JIkZLxXr"
);

export const PayBillModal = ({
  setShowCreateModal,
  orderDetails,
  fromOrderCreate = false,
}: any) => {
  const { hasRestaurant, user } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [showBillAmountModal, setShowBillAmountModal] = useState(false);
  const [amount, setAmount] = useState("$");
  const [isSuggestionUsed, setIsSuggestionUsed] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [payment, setPayment] = useState("CASH");
  const [promoCode, setPromocode] = useState("");
  const [showPromoField, setShowPromoField] = useState(false);
  const [promoloading, setPromoloading] = useState(false);
  const [discountedAmount, setDiscountedAmount] = useState(
    itemsTotalPriceCalc(orderDetails?.items)
  );
  const [newPercentOff, setPercentOff] = useState(0)

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe" as "stripe",
    },
    loader: "auto",
  };

  useEffect(() => {
    if (payment === "CARD") {
      const initialize = async () => {
        try {
          const response: any = await axBe.post(
            `${API_ENDPOINT}stripe/checkout/customerPayment`,
            {
              hostName: `${user?.storeUser?.firstName} ${user?.storeUser?.lastName}`,
              customerEmail: user?.storeUser?.email,
              storeCorrelationId: hasRestaurant?.correlationId,
              customerOrderCorrelationId: orderDetails?.correlationId,
            }
          );
          setClientSecret(response.data?.clientSecret);
        } catch (error) {
          console.error("Error initializing payment:", error);
          failureToast(error)
        }
      };
      initialize();
    }
  }, [payment]);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const itemsTotalPrice = itemsTotalPriceCalc(orderDetails?.items);
  const itemTotalTax = itemTotalTaxCalc(orderDetails?.items);

  // const applyDiscount = (totalCartValue: number, percentOff: any) => {
  //   if (percentOff && percentOff > 0) {
  //     percentOff = Math.min(percentOff, 100);
  //     const discountAmount = totalCartValue * (percentOff / 100);
  //     const finalPrice = totalCartValue - discountAmount;
  //     return Number(finalPrice.toFixed(2));
  //   }
  //   return totalCartValue;
  // };

  const totalAmount = useMemo(() => {
    return discountedAmount + itemTotalTax;
  }, [discountedAmount, itemTotalTax]);

  const handleButtonClick = (value: any, type: any) => {
    const currentAmount = amount.replace("$", "");
    if (type === "suggestion") {
      setIsSuggestionUsed(true);
      setAmount(`$${value}`);
    } else if (type === "selection") {
      if (isSuggestionUsed) {
        setAmount(`$${value}`);
        setIsSuggestionUsed(false);
        return;
      }
      setAmount(amount ? `$${currentAmount + value}` : "$");
    } else {
      const modifiedAmount = currentAmount.slice(0, -1);
      setAmount(modifiedAmount ? `$${modifiedAmount}` : "$");
    }
  };

  const isButtonEnabled = useMemo(() => {
    const currentAmount = amount.replace("$", "");
    return +currentAmount >= totalAmount;
  }, [amount, totalAmount]);

  const handleCheckoutClick = () => {
    setShowBillAmountModal(true);
    updateUserOrder();
  };

  const updateUserOrder = async () => {
    const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
      query: UPDATE_CUSTOMER_ORDER,
      variables: {
        updateCustomerOrderInput: {
          storeCorrelationId: hasRestaurant?.correlationId,
          customerOrderCorrelationId: orderDetails?.correlationId,
          paid: true,
          payment: {
            paymentAmount: `${totalAmount?.toFixed(2)}`,
            paymentMethodType: "Cash",
            paymentStatus: "PAID",
          },
        },
      },
    });
    if (data.data.updateCustomerOrder) {
    }
  };

  const createPercentOff = async () => {
    setPromoloading(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_PERCENT_OFF,
        variables: {
          getPromotionCodeDetailsInput: {
            storeCorrelationId: hasRestaurant?.correlationId,
            promotionCode: promoCode,
            customerEmail: orderDetails?.customerEmail,
          },
        },
      });
      setPercentOff(data?.data?.promotionCodeDetails?.percentOff || 0);
      setPromoloading(false);
    } catch (error) {
      console.error(error);
      setPromoloading(false);
    }
  };

  const handleTotalOff = async () => {
    createPercentOff();
  };


  const percentOffNumber = Number(newPercentOff) || 0;
  const finalTotal = itemsTotalPrice * (1 - percentOffNumber/100) + itemTotalTax * ( 1 - percentOffNumber/100);

  useEffect(() => {
    if(newPercentOff){
      setPromocode("")
    }
  }, [newPercentOff])
  

  return (
    <>
      <div
        className="relative z-[99]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div
                className={`bg-white px-8 py-6 rounded-lg w-[820px] max-w-[820px] min-w-10 flex flex-col`}
              >
                <div className="top flex justify-between items-center">
                  <div>
                    <h1 className="text-lg font-semibold">Pay Bill</h1>
                  </div>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt=""
                    onClick={setShowCreateModal}
                  />
                </div>
                <div className="flex gap-x-6">
                  <div className="w-[50%] mt-8">
                    <h1 className="text-sm font-semibold">Customer Info</h1>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-2 mt-2">
                        <span className="h-11 w-16 rounded-lg bg-[#FD8064] flex justify-center items-center">
                          <h1 className="text-white text-sm">
                            {orderDetails?.customerFirstName
                              .charAt(0)
                              ?.toUpperCase()}
                            {orderDetails?.customerLastName
                              ?.charAt(0)
                              ?.toUpperCase()}
                          </h1>
                        </span>

                        <div>
                          <h1 className="font-medium text-sm">
                            {`${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`}
                          </h1>
                          <h1 className="font-normal text-xs text-[#666666]">
                            Order {`#${orderDetails?.correlationId}`}
                          </h1>
                        </div>
                      </div>
                      <div>
                        <h1 className="font-normal text-xs text-[#666666]">
                          {formatDateTime(orderDetails?.createdAt)}
                        </h1>
                      </div>
                    </div>
                    <div
                      ref={contentRef}
                      className="mt-6 bg_billed h-[470px] overflow-y-scroll p-2 relative"
                    >
                      <div className="p-2">
                        <h2 className="text-md font-semibold mb-4">
                          Customer Info
                        </h2>

                        <div className="space-y-4">
                          {orderDetails?.items?.map(
                            (item: any, index: number) => {
                              return (
                                <div
                                  className="flex justify-between items-center"
                                  key={index}
                                >
                                  <div>
                                    <p className="text-sm text-[#666666]">
                                      {item?.englishName}
                                    </p>
                                    <p className="text-sm">{`$${item?.price}`}</p>
                                  </div>
                                  <span className="text-sm">{`$${item?.quantity}x`}</span>
                                </div>
                              );
                            }
                          )}
                        </div>

                        <div className="space-y-2 mt-14">
                          <div className="flex justify-between">
                            <p className="text-[#666666] text-sm">
                              {orderDetails?.items.length === 1
                                ? `Item (${orderDetails?.items.length})`
                                : `Items (${orderDetails?.items.length})`}
                            </p>
                            <p className="text-[#666666] text-sm">
                              {`$${itemsTotalPrice
                                ?.toString()
                                .replace(/^0+/, "")}`}
                            </p>
                          </div>
                          {newPercentOff > 0 && (
                              <div className="flex justify-between mt-4">
                                <p className="text-[#666666] text-sm">
                                  Discount{`(${newPercentOff}%)`}:
                                </p>
                                <p className="text-[#666666] text-sm">
                                  ${(itemsTotalPrice * (percentOffNumber/100))?.toFixed(2)}
                                </p>
                              </div>
                          )}
                          <div className="flex justify-between mt-4">
                            <p className="text-[#666666] text-sm">
                              Estimated tax:
                            </p>
                            <p className="text-[#666666] text-sm">
                              ${Number(itemTotalTax*(1 - percentOffNumber/100)).toFixed(2)}
                            </p>
                          </div>
                        </div>

                        <div className="flex justify-between items-center bottom-10 right-3 absolute w-[92%]">
                          <p className="text-lg font-semibold">Total</p>
                          <p className="text-lg font-semibold">
                            {`$ ${finalTotal?.toFixed(2)}`}
                          </p>
                        </div>
                      </div>
                    </div>
                    {!showPromoField && (
                      <button
                        className="bg-[#F5F5F6] px-4 py-2 text-sm text-black rounded-lg mt-2"
                        onClick={() => {
                          setShowPromoField(true);
                        }}
                      >
                        Have a promotion code?
                      </button>
                    )}

                    {showPromoField && (
                      <div>
                        <div className="!text-black !font-semibold mt-2">
                          Promo code / coupon
                        </div>
                        <div className="flex items-center w-full gap-x-2 justify-between">
                          <input
                            className="h-[40px] text-xs border rounded-lg border-[#F1F1F2] mt-1 px-2 w-full"
                            placeholder=" Enter your promo code / coupon"
                            type="text"
                            name=""
                            id=""
                            onChange={(e) => setPromocode(e.target.value)}
                            value={promoCode}
                          />
                          <div>
                            {promoloading ? (
                              <BtnLoader />
                            ) : (
                              <button
                                onClick={handleTotalOff}
                                className={` bg-[#FD7657] px-2 py-2 text-sm text-white rounded-lg w-[100px]`}
                              >
                                Apply now
                              </button>
                            )}
                          </div>
                        </div>

                        <button
                          className={`bg-[#F5F5F6] px-4 py-2 text-sm text-black rounded-lg mt-2`}
                          onClick={() => {
                            setShowPromoField(false);
                          }}
                        >
                          Hide
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="w-[50%] mt-8">
                    <h1 className="text-sm font-medium">
                      Select a payment method
                    </h1>
                    <select
                      name="roles"
                      className="text-[#] h-[38px] w-full text-xs  border rounded-lg border-[#F1F1F2] px-2 mt-2"
                      onChange={(e) => setPayment(e.target.value)}
                    >
                      <option className="text-[]" value="CASH">
                        Cash
                      </option>
                      <option className="text-[]" value="CARD">
                        Card
                      </option>
                    </select>

                    {payment === "CASH" ? (
                      <div className="flex flex-col mt-2">
                        <div className="w-full text-center mb-4">
                          <input
                            type="text"
                            value={amount}
                            className="w-full text-3xl font-semibold border-gray-300 focus:outline-none focus:border-orange-500 text-center py-2"
                            readOnly
                          />
                        </div>
                        <div className="flex space-x-3 mb-6 overflow-x-scroll">
                          {[totalAmount.toFixed(), 10, 20, 50, 100, 150].map(
                            (selectedValue) => (
                              <button
                                key={selectedValue}
                                className="bg-[#FD76570D] text-[#FD7657] py-1 px-4 rounded-md"
                                onClick={() =>
                                  handleButtonClick(selectedValue, "suggestion")
                                }
                              >
                                ${selectedValue}
                              </button>
                            )
                          )}
                        </div>
                        <div className="grid grid-cols-3 gap-4 text-center text-2xl">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0]?.map((value) => (
                            <button
                              key={value}
                              className="py-4"
                              onClick={() => {
                                handleButtonClick(value, "selection");
                              }}
                            >
                              {value}
                            </button>
                          ))}
                          <button
                            className="py-4"
                            onClick={() => handleButtonClick("⌫", "")}
                          >
                            ⌫
                          </button>
                        </div>
                        <div className="mt-8">
                          <button
                            onClick={handleCheckoutClick}
                            disabled={!isButtonEnabled}
                            className={`${
                              !isButtonEnabled && "opacity-20"
                            } w-full bg-[#FD7657] text-white py-3 rounded-lg shadow-lg`}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {clientSecret && (
                          <Elements
                            options={options as any}
                            stripe={stripePromise}
                          >
                            <CheckoutForm />
                          </Elements>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBillAmountModal && (
        <PayBillAmountModal
          onClick={() => reactToPrintFn()}
          paidAmount={+amount.replace("$", "")}
          actualAmount={totalAmount}
          showBillAmountModal={() => setShowBillAmountModal(false)}
          setShowCreateModal={setShowCreateModal}
          fromOrderCreate={fromOrderCreate}
        />
      )}
    </>
  );
};
