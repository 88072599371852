import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { useFormik } from "formik";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { UPDATE_RESTAURANT } from "src/constants/mutations";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { successToast } from "src/utils/toasts";
import { BtnLoader } from "src/Loader";

const SuggestedTips = () => {
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant, sethasRestaurant } = useAuth();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      suggestedTipPercentages: [],
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (hasRestaurant?.suggestedTipPercentages?.length > 0) {
      const tips = hasRestaurant?.suggestedTipPercentages.map(
        (tip: any) => tip.tipPercentage || tip
      );
      formik.setFieldValue("suggestedTipPercentages", tips);
    }
  }, [hasRestaurant]);

  const updateRestaurantSetting = async () => {
    setLoading(true);
    try {
      const formattedTips = formik.values.suggestedTipPercentages.map((tip) => {
        const strValue = String(tip);
        return strValue.replace("%", "");
      });

      const variables = {
        updateStoreInput: {
          ...formik.values,
          suggestedTipPercentages: formattedTips,
          storeCorrelationId: hasRestaurant.correlationId,
        },
      };
      const { data }: any = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_RESTAURANT,
        variables: variables,
      });
      const payloadData = {
        ...hasRestaurant,
        suggestedTipPercentages: formattedTips,
      };
      localStorage.setItem("restaurant", JSON.stringify(payloadData));
      sethasRestaurant(payloadData);
      setLoading(false);

      if (!data.errors) {
        successToast("Suggested Tip Percentages have been Updated!");
      }
    } catch (error) {
      console.error("Error updating restaurant:", error);
      setLoading(false);
    }
  };

  const handleTagsInputChange = (value: string[]) => {
    const validValues = value
      .map((v) => {
        const strValue = String(v);
        const numericValue = strValue.replace("%", "");
        if (/^\d+(\.\d{1,2})?$/.test(numericValue)) {
          return numericValue;
        }
        return "";
      })
      .filter((v) => v !== "");
    formik.setFieldValue("suggestedTipPercentages", validValues);
  };

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <>
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col my-6 w-[90%]">
          <h1 className="font-medium text-lg my-2">Suggest Tips</h1>
          <div>
            <TagsInput
              value={formik.values.suggestedTipPercentages}
              name="suggestedTipPercentages"
              placeHolder="Enter Tips"
              onChange={handleTagsInputChange}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center w-full">
        <div className="w-[90%]">
          {loading ? (
            <BtnLoader />
          ) : (
            <button
              disabled={isDisabled}
              className={`${
                isDisabled && "opacity-20"
              } bg-orange text-white rounded-lg w-[85px] h-[35px] text-xs`}
              onClick={updateRestaurantSetting}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SuggestedTips;
