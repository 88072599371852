import moment from "moment";

export const convertMillisecondsToTime = (
  milliseconds: number,
  date = new Date()
) => {
  // Create a moment object for the given date
  const momentDate = moment(date).startOf("day");

  // Add the milliseconds to the start of the day
  const timeMoment = momentDate.add(milliseconds, "milliseconds");

  // Format as needed
  return timeMoment;
};

export const formatTimeAgo = (timestamp: number) => {
  const now = moment();
  const inputTime = moment(timestamp);

  const diffInMinutes = now.diff(inputTime, "minutes");
  const diffInHours = now.diff(inputTime, "hours");
  const diffInDays = now.diff(inputTime, "days");
  const diffInWeeks = now.diff(inputTime, "weeks");
  const diffInMonths = now.diff(inputTime, "months");
  const diffInYears = now.diff(inputTime, "years");

  if (diffInMinutes < 1) {
    return "Just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} min ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  } else {
    return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
  }
};

export const convertDateToMilliseconds = (date: any) => {
  const timeStr = moment(date).format("HH:mm");
  const hour = moment(timeStr, "HH:mm");
  return hour.hour() * 3600000 + hour.minute() * 60000;
};

export const formatDate = (date: number) =>
  new Date(date).toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

export const convertWithTime = (milliseconds: number) => {
  return moment(milliseconds).format("h:mm a");
};

export const convertDateToTimeStamp = (date: any) => {
  const formatDate: any = moment(date).format("MM-DD-YYYY");
  const timestamp = moment(formatDate).valueOf();
  return timestamp;
};

export const formatDateTime = (date: number) =>
  new Date(date).toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
