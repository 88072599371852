import { useState, useEffect, useRef, useCallback } from "react";
import SockJS from "sockjs-client";
import { Client, Stomp } from "@stomp/stompjs";
import { API_ENDPOINT } from "src/constants/endpoints";
import { set_notification } from "src/store/features/notification-slice";
import { useAxiosInterceptor } from "./useAxiosInterceptor";


export const useProcessMonitoring = () => {
  const [isIncoming, setIsincoming] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const stompClientRef = useRef<Client | any>(null);
  const { axBe } = useAxiosInterceptor();
  const messageQueue = useRef<Array<{ destination: any, message: any, dispatch: any }>>([]);
  const baseUrl = new URL(API_ENDPOINT).origin;

  const sendMessage = (destination: string, message: any, dispatch: any, stompClient: any) => {
    if (stompClient) {
      stompClient?.send(destination, {}, JSON.stringify({latestCustomerOrderCorrelationId: message}));
    } else {
      console.log("WebSocket client is not connected. Queuing message.");
      // messageQueue.current.push({ destination, message, dispatch });
    }
  };

  const startMonitoring = useCallback((correlationId: string, token: any, dispatch: any, restaurantNotifications: any, isSync?: boolean) => {
    setIsMonitoring(true);
    console.log('useProcess START MONITOR', isMonitoring);
    const socket = new SockJS(`${baseUrl}/ws`);
    const stompClient = Stomp.over(socket);

    stompClient.connect({ Authorization: `Bearer ${token}` }, (frame: any) => {
      stompClientRef.current = stompClient;
      setIsConnected(true);
      console.log('WebSocket connected:', frame);

      stompClient.subscribe(`/topic/store/${correlationId}`, (msg) => {
        const newMessage = JSON.parse(msg.body);
          dispatch(set_notification({ correlationId, notification: newMessage }));
        setIsincoming(true);
      });
      if (restaurantNotifications?.latestCustomerOrderCorrelationId) {
        const latestCustomerOrderCorrelationId = restaurantNotifications.latestCustomerOrderCorrelationId;
        sendMessage(`/app/missedCustomerOrderCreationNotifications`, latestCustomerOrderCorrelationId, dispatch, stompClient);
      }
    });
    console.log(stompClientRef.current, 'useProcess CLIENT');
  }, []);


  const stopMonitoring = useCallback(() => {
    console.log('useProcess STOPMONITOR FIRST TIME ONLY')

    setIsMonitoring(false);
    if (stompClientRef.current) {
      stompClientRef.current.deactivate();
      stompClientRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      stopMonitoring();
    };
  }, [stopMonitoring]);

  return { isMonitoring, startMonitoring, stopMonitoring, isIncoming, setIsincoming };
};
