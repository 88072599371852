import React, { useState, useEffect } from "react";
import Header from "../components/Header/index";
import EmployeeSidebar from "../components/EmployeeSidebar";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "src/hooks/useIdleTimer";
import { LoginExpireModal } from "src/components/LoginExpireModal/LoginExpiremodal";
import { useDispatch, useSelector } from "react-redux";
import ShowTokenRefreshModal from "src/components/ShowTokenRefreshModal/ShowTokenRefreshModal";
import { useAuth } from "src/contexts/auth-provider";
import DeleteCombo from "src/components/DeleteRestaurant/DeleteCombo";
import { useProcessMonitoring } from "src/hooks/useProcessMonitoring";
// import { set_sync_notification } from "src/store/features/notification-slice";

export const DefaultEmployeeLayout = () => {
  
  const [sidebarOpen, setSidebarOpen] = useState(
    window.innerWidth <= 768 ? false : true
  );
  const [pageTitle, setPageTitle]: any = useState("Active Orders");
  const { user, hasRestaurant, token } = useAuth();
  const auth = useSelector((state: any) => state.auth);
  const { hasComboId } = useSelector((state: any) => state.combo);
  const {notifications}  = useSelector((state: any) => state.notifications);
  const { pathname } = useLocation();
  const { startMonitoring, stopMonitoring, isIncoming, setIsincoming} = useProcessMonitoring();
  const { correlationId } = hasRestaurant || {};
  const dispatch = useDispatch()
  const restaurantNotifications = notifications?.[hasRestaurant?.correlationId]

  useIdleTimer();

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth <= 768 && setSidebarOpen(false);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!correlationId) {
      stopMonitoring();
      // dispatch(set_sync_notification(false));
      return;
    }
    startMonitoring(correlationId, token, dispatch, restaurantNotifications);

    return () => {
      stopMonitoring();
      // dispatch(set_sync_notification(false));
    };
  }, [startMonitoring, correlationId, token]);

  const hideLayout = [
    "/create-restaurant",
    "/upload-image",
    "/plans",
    "/stripe-connection",
    "/success",
    "/cancel",
    "/loginexpair",
    "/create-new-order"
  ].includes(pathname);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark h-full">
      {hasComboId && <DeleteCombo comboId={hasComboId} />}
      {auth.showExpiryLoginModal && <LoginExpireModal />}
      {auth.isShowTokenRefreshModal && <ShowTokenRefreshModal />}


      <div className="flex h-full overflow-hidden">
        {!hideLayout && (
          <EmployeeSidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setPageTitle={setPageTitle}
          />
        )}

        <div className="relative flex flex-1 bg-[#FAFAFA] flex-col overflow-y-auto">
          {!hideLayout && (
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              user={user}
              pageTitle={pageTitle}
              notification={restaurantNotifications?.notifications}
              isIncoming={isIncoming}
              setIsincoming={setIsincoming}
            />
          )}
          <main>
            <div className={
                !hideLayout
                  ? "mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10"
                  : ""
              }>
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
