import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BtnLoader } from "src/Loader/index";
import EmployeeCard from "src/components/EmployeeCard/Employee";
import { CreateEmployeeModal } from "src/components/CreateEmployeeModal/CreateEmployeeModal";
import { FRONTEND_URL, GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GET_EMPLOYEE } from "src/constants/queries";
import {
  delete_employee_modal,
  search_employees,
  set_employees,
} from "src/store/features/employee-slice";

const Employee = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredEmployees, setFilteredEmployees] = useState<any>([]);
  const dropdownRefs = useRef<any>([]);

  const { hasRestaurant, setHasEmployee } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const dispatch = useDispatch();

  const { filteredEmployees: data } = useSelector(
    (state: any) => state.employee
  );

  const handleCardRef = (index: any, ref: any) => {
    dropdownRefs.current[index] = ref; // Store the ref in the array
  };


  useEffect(() => {
    setFilteredEmployees(data);
  }, [data]);

  const handleEmployeeClick = (employeeId: string) => {
    dispatch(delete_employee_modal(employeeId));
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    setIsLoading(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_EMPLOYEE,
        variables: {
          storeCorrelationId: hasRestaurant.correlationId,
        },
      });
      dispatch(
        set_employees({
          employees: data?.data?.storeEmployeesByStoreCorrelationId || [],
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const editEmployeeModal = (editData: any) => {
    setEditValues(editData);
    setShowCreateModal(true);
  };

  const searchEmployee = (value: string) => {
    dispatch(search_employees(value));
  };

  const changeModeToEmployee = (employee: any) => {
    localStorage.setItem("employee", JSON.stringify(employee));
    setHasEmployee(employee);
    window.location.href = `${FRONTEND_URL}/my-employee/${employee.correlationId}/orders`;
  };

  return (
    <>
      <div
        className="flex justify-between items-center"
      >
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Employee..."
                onChange={(e) => searchEmployee(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div>
          <div className="flex justify-center my-4">
            <button
              className="hover:bg-white hover:text-orange duration-500 w-[200px] text-white h-[42px] rounded-xl bg-[#FD7657]"
              onClick={() => setShowCreateModal(true)}
            >
              &#65291; Invite Employee
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-6 gap-3">
        {isLoading ? (
          <div className="absolute top-[50%] right-[50%]">
            <BtnLoader />
          </div>
        ) : !filteredEmployees?.length ? (
          <div className="absolute top-[50%] right-[45%] text-[#666666] text-sm">
            <span>No Employee found!</span>
          </div>
        ) : (
            filteredEmployees?.map((employee: any, index: any) => (
              <EmployeeCard
                key={index}
                employee={employee}
                handleEmployeeClick={handleEmployeeClick}
                editEmployeeModal={editEmployeeModal}
                changeModeToEmployee={changeModeToEmployee}
                index={index}
                ref={(el) => handleCardRef(index, el)}
                />
            ))
        )}
      </div>
      {showCreateModal && (
        <CreateEmployeeModal
          onClose={() => setShowCreateModal(false)}
          editValues={editValues}
          getEmployees={getEmployees}
          setEditValues={setEditValues}
        />
      )}
    </>
  );
};

export default Employee;
