import { forwardRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { ADD_PROMOTION } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { checkEmailValid } from "src/utils/email";
import { BtnLoader } from "src/Loader";

const PromotionSchema = Yup.object().shape({
  userEmails: Yup.array()
    .of(Yup.string().email("Invalid email"))
    .min(1, "Please enter at least 1 emails.")
    .required("Required"),
  expiry: Yup.string().required("Expiry date is required"),
  maxNumberOfRedemptions: Yup.string().required(
    "Max Number Of Redemptions date is required"
  ),
  percent: Yup.number()
    .min(0, "Must be at least 0%")
    .max(100, "Must be 100% or less")
    .required("Percentage is required"),
  isExistingCustomer: Yup.boolean(),
});

export const CreatePromotion = ({ isShowEmailInput = true }: any) => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [emailFields, setEmailFields] = useState([""]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      userEmails: [],
      expiry: "",
      percent: "",
      maxNumberOfRedemptions: "",
      isExistingCustomer: false,
    },
    validationSchema: PromotionSchema,
    onSubmit: () => {},
  });

  const DateCustomInput = forwardRef(
    ({ value, onClick, className, autoComplete }: any, ref: any) => (
      <input
        name="expiry"
        type="text"
        className={
          `h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2 ` +
          className
        }
        placeholder="Enter expiry"
        onClick={onClick}
        ref={ref}
        autoComplete={autoComplete}
        value={value}
      />
    )
  );

  const handleEmailChange = (index: number, value: string) => {
    const updatedEmailFields = [...emailFields];
    updatedEmailFields[index] = value;
    setEmailFields(updatedEmailFields);
    formik.setFieldValue("userEmails", updatedEmailFields);
  };

  const addEmailField = () => {
    setEmailFields([...emailFields, ""]);
  };

  const removeEmailField = (index: number) => {
    const updatedEmailFields = emailFields.filter((_, i) => i !== index);
    setEmailFields(updatedEmailFields);
    formik.setFieldValue("userEmails", updatedEmailFields);
  };

  const submitPromotion = async () => {
    setLoading(true);
    const {
      userEmails,
      expiry,
      percent,
      isExistingCustomer,
      maxNumberOfRedemptions,
    } = formik.values;
    const variables: any = {
      createPromotionCodeInput: {
        userEmails: userEmails,
        promotionExpiry: Math.floor(new Date(expiry).getTime() / 1000),
        percentOff: percent,
        maxRedemptions: Number(maxNumberOfRedemptions),
        isExistingCustomer: isExistingCustomer,
        storeCorrelationId: hasRestaurant?.correlationId,
      },
    };
    const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
      query: ADD_PROMOTION,
      variables: variables,
    });
    setLoading(false);
    if (data?.data?.createPromotionCode?.length) {
      formik.setFieldValue("expiry", "");
      formik.setFieldValue("percent", "");
      formik.setFieldValue("userEmails", []);
      formik.setFieldValue("maxNumberOfRedemptions", "");
      setEmailFields([""]);
      setLoading(false);
      successToast("Promotion Created!");
    } else {
      formik.setFieldValue("expiry", "");
      formik.setFieldValue("percent", "");
      formik.setFieldValue("userEmails", []);
      formik.setFieldValue("maxNumberOfRedemptions", "");
      setEmailFields([""]);
      setLoading(false);
    }
  };

  console.log(formik);
  

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="bg-white rounded-lg w-[50%] relative mt-10">
          <div className="pb-4 pt-8 w-[95%] m-auto">
            <div>
              {isShowEmailInput && (
                <div>
                  <label className="block text-sm text-[#9B9B9B] font-medium p-1">
                    Use 0 or more emails to create promotion codes. When emails
                    are used, the generated promotion code will be automatically
                    sent to the given emails.
                  </label>
                  <div className="space-y-3">
                    {emailFields.map((email, index) => (
                      <div key={index} className="flex flex-col space-x-2">
                        <input
                          type="email"
                          className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) =>
                            handleEmailChange(index, e.target.value)
                          }
                        />
                        {email && !checkEmailValid(email) && (
                          <p className="text-[red] text-xs mt-2">
                            Invalid email
                          </p>
                        )}
                        {/* {emailFields.length > 1 && (
                          <button
                            type="button"
                            className="text-red-500 text-xs mt-2"
                            onClick={() => removeEmailField(index)}
                          >
                            Remove
                          </button>
                        )} */}
                      </div>
                    ))}
                    <button
                      type="button"
                      className="text-[#FD7657] text-xs mt-2 bg-[#FD76571A] rounded-lg py-2 px-3"
                      onClick={addEmailField}
                    >
                      + Add More Email
                    </button>
                  </div>
                </div>
              )}
              <div className="mt-2 w-full">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Expiry
                </label>
                <DatePicker
                  selected={formik.values.expiry as any}
                  onChange={(date) => formik.setFieldValue("expiry", date)}
                  customInput={<DateCustomInput />}
                  minDate={new Date()}
                  autoComplete="off"
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Percent
                </label>
                <input
                  name="percent"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter percent"
                  value={formik.values.percent}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Max number of redemptions
                </label>
                <input
                  defaultValue={formik.values.maxNumberOfRedemptions}
                  name="maxNumberOfRedemptions"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter Max number of redemptions"
                  value={formik.values.maxNumberOfRedemptions}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-6 flex justify-end space-x-2">
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button
                    className={`${
                      !formik.isValid && "opacity-20"
                    } ${!formik.dirty && "opacity-20"} text-white bg-orange text-sm w-24 h-[35px] rounded-lg`}
                    onClick={submitPromotion}
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
