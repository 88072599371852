import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications: {} as any,
    isSync: false
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        set_notification: (state, { payload }) => {
          const { correlationId, notification } = payload;
        
          if (!state.notifications[correlationId]) {
            state.notifications[correlationId] = { notifications: [], latestCustomerOrderCorrelationId: '' };
          }
        
          const notificationsArray = Array.isArray(notification) ? notification : [notification];
          console.log(notificationsArray, notification, 'ArrayNotify')
        
          notificationsArray.forEach(notification => {
            const exists = state.notifications[correlationId].notifications.some((msg: any) => msg.orderId === notification.orderId);
            if (!exists) {
              state.notifications[correlationId].notifications.push(notification);
              state.notifications[correlationId].latestCustomerOrderCorrelationId = notification.orderId;
            }
          });
        },
        set_sync_notification: (state, { payload }) => {
            const { isSync } = payload;
            state.isSync = isSync;
        },
      remove_notification : (state, {payload}) => {
        console.log('remove', payload)
        const {correlationId, removeOrderId} = payload
        state.notifications[correlationId].notifications = state.notifications[correlationId].notifications.filter((item: any) => item.orderId !== removeOrderId); 
        console.log('remove', state.notifications);       
      },
      clear_notification : (state, {payload})=> {
        state.notifications[payload].notifications = []
      },
      clear_signout_notification : (state)=> {
        state.notifications = {}
      }
    },
  });

  export const { set_notification, remove_notification, clear_notification, set_sync_notification,clear_signout_notification } = notificationsSlice.actions;
  export default notificationsSlice.reducer;
