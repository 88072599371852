import { formatDateTime } from "src/utils/date";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import CrossIcon from "../../assests/images/dashboard/Vector 5.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import { useNavigate } from "react-router-dom";
import {
  getItemOptionElementPrice,
  itemTotalTaxCalc,
  itemsTotalPriceCalc,
} from "src/utils/order-price-calculation";
import { useCallback, useEffect, useState } from "react";
import {
  GET_CUSTOMERS_BY_CORRELATIONIDS,
  GET_DELIVERY_CHARGES,
  GET_RECEIPT,
} from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { BtnLoader } from "src/Loader";
import { useWebSocketSender } from "src/hooks/useSendMassageToWs";

export const OrdersDetailsEmployee = ({
  setShowOrderDetail,
  orderDetails,
}: any) => {
  const { startConnection, stopConnection, sendMessage, isSending } =
    useWebSocketSender();
  const { axBe } = useAxiosInterceptor();
  const navigate = useNavigate();
  const { hasRestaurant, user, userRole, token } = useAuth();
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [customersDetails, setCustomersDetails] = useState<any>([]);
  const [receiptLoader, setReceiptLoader] = useState(false);

  const itemsTotalPrice = itemsTotalPriceCalc(orderDetails?.items);
  const itemTotalTax = itemTotalTaxCalc(orderDetails?.items);
  const totalAmount = +itemsTotalPrice + +itemTotalTax;

  const itemTotalPrice = useCallback(
    (item: any) => getItemOptionElementPrice(item).toFixed(2),
    []
  );

  const getCustomers = async () => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMERS_BY_CORRELATIONIDS,
        variables: {
          customersByCorrelationIdsInput: {
            customerCorrelationIds: [orderDetails?.customerCorrelationId],
            storeCorrelationId: hasRestaurant?.correlationId,
          },
        },
      });
      setCustomersDetails(data?.data?.customersByCorrelationIds || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [orderDetails]);

  useEffect(() => {
    if (orderDetails.orderType === "DELIVERY" && customersDetails) {
      const getDeliveryChargers = async () => {
        const isEmployee = userRole.toLowerCase().includes("employee");
        try {
          const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
            query: GET_DELIVERY_CHARGES,
            variables: {
              deliveryChargeInput: {
                storeCorrelationId: isEmployee
                  ? user?.storeUser?.stores?.[0]?.correlationId
                  : hasRestaurant?.correlationId,
                deliveryAddress: customersDetails?.[0]?.address,
              },
            },
          });
          setDeliveryCharges(data?.data?.deliveryCharge || []);
        } catch (error) {
          console.error(error);
        }
      };
      const timeoutId = setTimeout(() => {
        getDeliveryChargers();
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [
    orderDetails.orderType === "DELIVERY",
    customersDetails,
    userRole,
    hasRestaurant,
  ]);

  const getReceipt = async () => {
    const isEmployee = userRole.toLowerCase().includes("employee");
    setReceiptLoader(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_RECEIPT,
        variables: {
          getReceiptPreSignedUrlInput: {
            storeCorrelationId: isEmployee
              ? user?.storeUser?.stores?.[0]?.correlationId
              : hasRestaurant?.correlationId,
            customerOrderCorrelationId: orderDetails?.correlationId,
          },
        },
      });
      setReceiptLoader(false);
    } catch (error) {
      console.error(error);
      setReceiptLoader(false);
    }
  };

  const handleSendMessage = (receiptType: string) => {
    const messagePayload: any = {
      customerOrderCorrelationId: orderDetails?.correlationId,
      receiptType: receiptType,
    };
    sendMessage("/app/manualPrinter", messagePayload);
  };

  useEffect(() => {
    if (token) {
      startConnection(token);
    }
    return () => {
      stopConnection();
    };
  }, [token, startConnection, stopConnection]);

  return (
    <>
      <div className="relative min-h-[800px] rounded-2xl w-[385px] bg-[#FFFFFF] shadow-2xl px-5 py-6">
        <div className="absolute top-4 right-0">
          <img
            onClick={setShowOrderDetail}
            className="mr-6 cursor-pointer"
            src={CrossIcon}
            alt=""
          />
        </div>
        <div className="flex mt-4 items-center justify-between">
          <h1 className="text-base font-medium"> Order Details</h1>
        </div>

        <div className="mt-3">
          <h1 className="text-sm text-[#252525]  mt-1">
            {" "}
            {`Recipient: ${
              orderDetails?.customerFirstName == "null"
                ? "-"
                : orderDetails?.customerFirstName || "-"
            } ${
              orderDetails?.customerLastName == "null"
                ? "-"
                : orderDetails?.customerLastName || "-"
            }`}
          </h1>
          <h1 className="text-sm text-[#252525] mt-1">
            {" "}
            {`Customer email: ${
              orderDetails?.customerEmail == "null"
                ? "-"
                : orderDetails?.customerEmail || "-"
            }`}
          </h1>
          <h1 className="text-sm text-[#252525] mt-1">
            {" "}
            {`Server name: ${
              orderDetails?.serverFirstName == "null"
                ? "-"
                : orderDetails?.serverFirstName || "-"
            } ${orderDetails?.serverLastName || ""}`}
          </h1>
          <h1 className="text-sm text-[#252525] mt-1">
            {" "}
            {`Table number: ${
              orderDetails?.tableNumber == "null"
                ? "-"
                : orderDetails?.tableNumber || "-"
            }`}
          </h1>
          <h1 className="text-sm text-[#252525] mt-1">
            {" "}
            {`Customer note: ${
              orderDetails?.customerNote == "null"
                ? "-"
                : orderDetails?.customerNote || "-"
            }`}
          </h1>
          <h1 className="text-sm text-[#666666] mt-1">
            {formatDateTime(orderDetails?.createdAt)}
          </h1>
          <h1 className="text-sm text-[#666666] mt-1">{`#${orderDetails?.correlationId}`}</h1>
        </div>
        <div className="divider border-[#EBF5FF] border-[1px] mt-3"></div>
        <div className="overflow-y-scroll h-[330px]">
          {orderDetails?.items?.map((item: any, index: number) => {
            return (
              <div className="mt-4 flex items-center gap-x-4" key={index}>
                <div className="relative w-[61px] h-[61px] rounded-full border border-[#F1F1F2]">
                  <img
                    className="absolute object-cover h-full w-full rounded-full"
                    src={
                      item?.bucketKeyName
                        ? item?.bucketKeyName?.includes("blob")
                          ? item?.bucketKeyName
                          : S3_BASE_URL + item?.bucketKeyName
                        : RestuarantImg
                    }
                    alt=""
                  />
                </div>
                <div className="border-b border-[#EBF5FF] pb-3 w-[70%]">
                  <h1 className="text-base font-medium text-[#252525]">
                    {item?.englishName}
                  </h1>
                  <h1 className="text-xs text-[#252525] mt-1">{`Quantity: ${
                    item?.quantity || 1
                  }`}</h1>
                  <h1 className="text-xs text-[#252525] mt-1">{`$${itemTotalPrice(
                    item
                  )}`}</h1>
                </div>
              </div>
            );
          })}
        </div>
        <div className="absolute bottom-4 w-[90%]">
          <div className="flex items-center justify-between mt-2">
            <h1 className="text-sm text-[#9B9B9B]"> Items</h1>
            <h1 className="text-base  text-[#252525]">
              {" "}
              {`$${itemsTotalPrice?.toFixed(2)}`}
            </h1>
          </div>
          <div className="flex items-center justify-between mt-2">
            <h1 className="text-sm  text-[#9B9B9B]"> Estimated tax:</h1>
            <h1 className="text-base text-[#252525]">
              {" "}
              ${parseFloat(itemTotalTax || "0").toFixed(2)}
            </h1>
          </div>
          {deliveryCharges?.length > 0 && (
            <div className="flex items-center justify-between mt-2">
              <h1 className="text-sm  text-[#9B9B9B]">Delivery Charges :</h1>
              <h1 className="text-base text-[#252525]">${deliveryCharges}</h1>
            </div>
          )}
          <div className="divider border-[#EBF5FF] border-[1px] mt-3 "></div>

          <div className="flex items-center justify-between mt-2 ">
            <h1 className="text-base text-[#252525]"> Total</h1>
            <h1 className="text-base text-[#252525]">
              {" "}
              ${totalAmount?.toFixed(2)}
            </h1>
          </div>
          {receiptLoader ? (
            <div className="h-[42px] flex items-center justify-center">
              <BtnLoader />
            </div>
          ) : (
            <>
              {isSending ? (
                <BtnLoader />
              ) : (
                <div className="flex items-center gap-x-3 mt-4">
                  <button
                    className="hover:bg-white hover:text-orange duration-500 w-[85%] text-xs text-white h-[42px] rounded-xl bg-[#FD7657]"
                    onClick={() => handleSendMessage("NON_KITCHEN")}
                  >
                    Print Receipt
                  </button>
                  <button
                    className="hover:bg-white hover:text-orange duration-500 w-[85%] text-xs text-white h-[42px] rounded-xl bg-[#FD7657]"
                    onClick={() => handleSendMessage("KITCHEN")}
                  >
                    Send to Kitchen
                  </button>
                  <button
                    className="hover:bg-white hover:text-orange duration-500 w-[85%] text-xs text-white h-[42px] rounded-xl bg-[#FD7657]"
                    onClick={() =>
                      navigate(
                        `/create-new-order?restaurantId=${hasRestaurant?.correlationId}`,
                        {
                          state: {
                            data: {
                              ...orderDetails,
                              customersDetails: customersDetails,
                            },
                          },
                        }
                      )
                    }
                  >
                    Update Order
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
