import { useCallback } from "react";
import { formatDateTime } from "src/utils/date";
import {
  getItemOptionElementPrice,
  itemTotalTaxCalc,
  itemsTotalPriceCalc,
} from "src/utils/order-price-calculation";

const OrderCard = ({ order, handleShowDetails }: any) => {
  const itemsTotalPrice = itemsTotalPriceCalc(order?.items);
  const itemTotalTax = itemTotalTaxCalc(order?.items);
  const totalAmount = +itemsTotalPrice + +itemTotalTax;

  const itemTotalPrice = useCallback(
    (item: any) => getItemOptionElementPrice(item).toFixed(2),
    []
  );

  return (
    <div className="w-full max-w-[650px] min-w-[265px] m-auto">
      <div className="relative border-[#F1F1F2] border bg-white rounded-xl">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <span className="h-11 w-14 rounded-lg bg-[#FD8064] flex justify-center items-center">
                <h1 className="text-white text-sm">
                  {order?.customerFirstName == "null"
                    ? "-"
                    : order?.customerFirstName?.charAt(0)?.toUpperCase() || ""}
                  {order?.customerLastName == "null"
                    ? "-"
                    : order?.customerLastName?.charAt(0)?.toUpperCase() || ""}
                </h1>
              </span>

              <div>
                <h1 className="font-medium text-sm">{`${
                  order?.customerFirstName == "null"
                    ? "-"
                    : order?.customerFirstName
                } ${
                  order?.customerLastName == "null"
                    ? "-"
                    : order?.customerLastName
                }`}</h1>
                <h1 className="font-normal text-xs text-[#666666]">
                  {`Order #${order?.correlationId}`}
                </h1>
              </div>
            </div>
            <span
              className={`${
                order?.orderStatus?.toLowerCase() === "voided" &&
                "bg-[#F144451A] text-[#F14445]"
              } ${
                order?.orderStatus?.toLowerCase() === "completed" &&
                "bg-[#3ACC481A] text-[#3ACC48]"
              } ${
                order?.orderStatus?.toLowerCase() === "pending" &&
                "bg-[#3A51CC1A] text-[#3A51CC]"
              } rounded-[8px] py-2 px-4 text-sm`}
            >
              {order?.orderStatus.charAt(0).toUpperCase() +
                order?.orderStatus.slice(1).toLowerCase() || "-"}
            </span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <h1 className="font-normal text-sm text-[#666666]">
              {`Created At: ${formatDateTime(order?.createdAt)}`}
            </h1>
          </div>
          <div className="flex justify-between items-center mt-1">
            <h1 className="font-normal text-sm text-[#666666]">
              {`Payment: ${order?.paid === true ? "Paid" : "Not paid"}`}
            </h1>
          </div>
          <div className="flex justify-between items-center mt-1">
            <h1 className="font-normal text-sm text-[#666666]">
              {`Table number: ${order?.tableNumber || "-"}`}
            </h1>
          </div>
          <div className="flex justify-between items-center mt-1">
            <h1 className="font-normal text-sm text-[#666666]">
              {`Server name: ${order?.serverFirstName || "-"} ${
                order?.serverLastName || ""
              }`}
            </h1>
          </div>
          <div className="divider border-[#EBF5FF] border-[1px] mt-2 "></div>
          <div className="flex items-center mt-4">
            <div className="w-[70%]">
              <h1 className="font-normal text-xs text-[#666666]">Items</h1>
            </div>
            <div className="flex items-center gap-x-12">
              <h1 className="font-normal text-xs text-[#666666] ">Qty</h1>
              <h1 className="font-normal text-xs text-[#666666] w-[45px]">
                Price
              </h1>
            </div>
          </div>
          <div className="h-[69px] overflow-y-scroll">
            {order?.items?.map((item: any, index: number) => {
              return (
                <div className="flex items-center mt-2" key={index}>
                  <div className="w-[70%]">
                    <h1 className="font-normal text-sm text-[#252525]">
                      {item?.englishName}
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-12">
                    <h1 className="font-normal text-sm text-[#252525] mr-1">
                      {item?.quantity}
                    </h1>
                    <h1 className="font-normal text-sm text-[#252525] w-[45px] ml-1">{`$${itemTotalPrice(
                      item
                    )}`}</h1>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="divider border-[#EBF5FF] border-[1px] mt-4 "></div>
          <div className="flex justify-between items-center mt-2 ">
            <h1 className="font-medium text-sm">Total</h1>
            <h1 className="font-medium mr-2 text-sm">
              ${`${totalAmount?.toFixed(2)}`}
            </h1>
          </div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <div className="flex justify-center w-full max-w-[155px]">
              <button
                className="px-8 py-[10px] text-nowrap w-full text-sm text-[#141212] h-[40px] rounded-xl bg-[#F5F5F6]"
                onClick={() => handleShowDetails(order)}
              >
                See Details
              </button>
            </div>
            {order?.paid === false && (
              <div className="flex justify-center ml-auto  w-full max-w-[155px]">
                <button
                  className="px-8 py-[10px] w-full text-nowrap text-sm text-white rounded-xl bg-[#FD7657]"
                  onClick={() => handleShowDetails(order, "pay bills")}
                >
                  Checkout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
