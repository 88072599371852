import { S3_BASE_URL } from "src/constants/endpoints";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";

const ComboCard = ({ combo, handleComboClick, navigateTo }: any) => {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="relative mx-2 mb-3 border-[#F1F1F2] border bg-white rounded-xl h-[250px] w-[255px]">
        <div
          className="flex justify-center bg-[#dadada] h-[140px] rounded-xl"
          onClick={() => navigateTo(combo)}
        >
          <img
            alt=""
            className="w-full rounded-t-xl cursor-pointer object-cover bg-no-repeat"
            src={
              combo?.bucketKeyName
                ? combo?.bucketKeyName.includes("blob")
                  ? combo?.bucketKeyName
                  : S3_BASE_URL + combo?.bucketKeyName
                : RestuarantImg
            }
          />
        </div>
        <div
          className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
          onClick={() =>  handleComboClick(combo?.correlationId)}
        >
          <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="Delete" />
        </div>

        <div className="pb-4 pr-4 pl-4 pt-[10px]">
          <div className="flex justify-between mt-1">
            <div className="tooltip">
                <h1 className="text-base tooltip-section w-[200px] truncate text-nowrap">{combo?.englishName}</h1>
                <span className="tooltip-text">{combo?.englishName}</span>
            </div>
          </div>
          <div className="">
            <div className="flex flex-wrap w-full gap-1  mt-1 items-center">
              {combo?.itemList && combo.itemList.length > 0 ? (
                combo?.itemList.slice(0, 4).map((item: any, index: any) => (
                  <>
                    <h1 className="text-xs text-[#A3A3A3]" key={index}>
                      {item?.englishName}
                    </h1>
                    <span className="w-[4px] h-[4px] bg-[#D9D9D9] rounded-full"></span>
                  </>
                ))
              ) : (
                <></>
              )}
              {combo.itemList?.length > 5 && (
                <div className="mb-1">
                  <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">
                    +{combo.itemList.length - 4}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboCard;
